/* eslint-disable max-lines
-- Disabled to set CI to fail on this issue on new files, PR #6757*/
import { createContext, useContext, useState } from "react";
import PropTypes from "prop-types";
import { t } from "i18n-js";
import { useQueryClient } from "react-query";
import { useHistory } from "react-router-dom";
import {
  reactQueryDelete,
  reactQueryGet,
  reactQueryPatch,
  reactQueryPost,
} from "@/react/helpers/backendRequestHelpers";
import { notifyBugsnag } from "@/react/helpers/bugsnagHelpers";
import { chatRoomsPath, internalApi } from "@circle-react/helpers/urlHelpers";
import { useToast } from "@circle-react-uikit/ToastV2";
import { DIRECT_CHAT_ROOM } from "../../components/constants";
import { embeddableChatApiQueryKey } from "../embeddable_chat/useEmbeddableChatsApi";
import { useDocumentTitle } from "../notifications/useDocumentTitle";
import { useDebounceApi } from "../useDebounceApi";

const MARK_READ_DEBOUNCE_TIMEOUT = 2000;
const ChatRoomsContext = createContext(initialState);
ChatRoomsContext.displayName = "ChatRoomsContext";

const initialState = {
  chatRooms: [],
};

export const ChatRoomsContextProvider = ({ children }) => {
  const [chatRooms, setChatRooms] = useState(initialState.chatRooms);
  const { addDirectMessageParticipantNameInTitle } = useDocumentTitle();
  const history = useHistory();
  const toast = useToast();
  const queryClient = useQueryClient();
  const { debouncedFunction } = useDebounceApi();

  const fetchChatRoomForUuid = (chatRoomsArr, uuid) =>
    chatRoomsArr.find(chatRoom => chatRoom.uuid === uuid);

  const sendRichTextMessage = async ({
    chatRoom,
    rich_text_body,
    currentCommunityMember,
    localAttachments,
    localSgidToObjectMap,
    parentMessageId,
  }) => {
    try {
      const chatRoomParticipantId = chatRoom.current_participant.id;

      if (value) {
        const chatRoomMessage = {
          chat_room_participant_id: chatRoomParticipantId,
          rich_text_body,
        };
        if (parentMessageId)
          chatRoomMessage["parent_message_id"] = parentMessageId;

        const res = await reactQueryPost(
          internalApi.chatRoomMessages.create({ uuid: chatRoom.uuid }),
          {
            chat_room_message: chatRoomMessage,
          },
        );
        const tempMessage = {
          id: null,
          rich_text_body: {
            ...rich_text_body,
            attachments: localAttachments,
            sgids_to_object_map: localSgidToObjectMap,
          },
          body: null,
          chat_room_uuid: chatRoom.uuid,
          chat_room_participant_id: chatRoomParticipantId,
          creation_uuid: res.creation_uuid,
          sender: chatRoom.current_participant,
          sent_at: res.sent_at,
        };
        if (parentMessageId) tempMessage["parent_message_id"] = parentMessageId;
        onNewMessage(
          {
            json_message: tempMessage,
          },
          currentCommunityMember.id,
          chatRooms,
        );
      }
    } catch (err) {
      toast.error(t("something_went_wrong"));
      notifyBugsnag(err);
    }
  };

  const appendChatRoom = (chatRoomUuid, chatRooms) => {
    reactQueryGet(internalApi.chatRooms.show({ uuid: chatRoomUuid }))
      .then(data => {
        if (data.chat_room) {
          setChatRooms([...new Set([data.chat_room, ...chatRooms])]);
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  const onNewMessage = (
    eventData,
    communityMemberId,
    chatRoomsData,
    isOpenChatRoom,
  ) => {
    const latestChatRoomMessage = eventData.json_message;
    const { chat_room_uuid, sender, embedded, parent_message_id } =
      latestChatRoomMessage;

    if (parent_message_id) return;
    const { community_member_id } = sender;
    const messageChatRoom = fetchChatRoomForUuid(chatRoomsData, chat_room_uuid);
    if (messageChatRoom) {
      const { unread_messages_count } = messageChatRoom;

      // If chat room is open then mark all messages as read
      // isOpen is not available for chat spaces
      if (isOpenChatRoom) {
        //Debounce the API call to mark all messages as read
        debouncedFunction(
          markChatRoomMessagesAsRead,
          MARK_READ_DEBOUNCE_TIMEOUT,
          chat_room_uuid,
        );
      }

      const remainingChatRooms = chatRoomsData.filter(
        chatRoom => chatRoom !== messageChatRoom,
      );

      //community_member_id: sender of the message
      //communityMemberId: current logged in community member
      const updatedUnreadMessageCount = isOpenChatRoom
        ? 0
        : community_member_id === communityMemberId
        ? unread_messages_count
        : unread_messages_count + 1;

      const updatedChatRoom = {
        ...messageChatRoom,
        last_message: latestChatRoomMessage,
        unread_messages_count: updatedUnreadMessageCount,
      };

      // The chat room with latest message will be at the top of the list
      setChatRooms([updatedChatRoom, ...remainingChatRooms]);
    } else {
      // The message is received in the chat room which is not present in the chatRooms state.
      // So we need to fetch the chat room data from backend and add it to chatRooms
      if (embedded !== true) {
        appendChatRoom(chat_room_uuid, chatRoomsData);
      }
    }
  };

  const onChatRoomRead = eventData => {
    const { chat_room_uuid } = eventData;
    const chatRoomIndex = chatRooms.findIndex(
      chatRoom => chatRoom.uuid === chat_room_uuid,
    );
    if (chatRoomIndex > -1) {
      const messageChatRoom = chatRooms[chatRoomIndex];
      const updatedChatRooms = [
        ...chatRooms.slice(0, chatRoomIndex),
        { ...messageChatRoom, unread_messages_count: 0 },
        ...chatRooms.slice(chatRoomIndex + 1),
      ];
      setChatRooms(updatedChatRooms);
    }
  };

  const onChatRoomCreated = eventData => {
    const { chat_room } = eventData || {};
    if (chatRooms.findIndex(room => room.uuid == chat_room.uuid) == -1) {
      setChatRooms(prevRooms => [chat_room, ...prevRooms]);
    }
  };

  const onChatRoomUpdated = eventData => {
    const { chat_room } = eventData || {};
    const updatedChatRooms = chatRooms.map(chatRoom => {
      if (chatRoom.uuid === chat_room.uuid) {
        return {
          ...chatRoom,
          chat_room_name: chat_room.chat_room_name,
        };
      }
      return chatRoom;
    });
    setChatRooms(updatedChatRooms);
  };

  const onChatRoomDetailsUpdated = (eventData = {}, chatRoomUuid) => {
    const { chat_room } = eventData;
    const updatedChatRooms = chatRooms.map(chatRoom => {
      if (chatRoom.uuid === chatRoomUuid) {
        return {
          ...chatRoom,
          chat_room_description: chat_room.chat_room_description,
          chat_room_show_history: chat_room.chat_room_show_history,
          pinned_message: chat_room.pinned_message,
        };
      }
      return chatRoom;
    });
    setChatRooms(updatedChatRooms);
  };

  const onChatRoomParticipantsUpdated = eventData => {
    const updatedChatRoom =
      typeof eventData.chat_room === "object"
        ? eventData.chat_room
        : JSON.parse(eventData.chat_room);
    const updatedChatRooms = chatRooms.map(chatRoom => {
      if (chatRoom.uuid === updatedChatRoom.uuid) {
        return updatedChatRoom;
      }
      return chatRoom;
    });
    setChatRooms(updatedChatRooms);

    // Need to refetch chat room if current participant was updated
    // This is necessary to reflect changes in the UI
    queryClient.invalidateQueries(
      embeddableChatApiQueryKey(updatedChatRoom.uuid),
    );
  };

  const onChatRoomParticipantDestroy = eventData => {
    const { chat_room_uuid } = eventData;
    const updatedChatRooms = chatRooms.filter(
      chatRoom => chatRoom.uuid !== chat_room_uuid,
    );
    setChatRooms(updatedChatRooms);
    history.push(chatRoomsPath());
  };

  const onEventReceive = (
    eventData,
    communityMemberId,
    chatRoomsData,
    isOpenChatRoom,
  ) => {
    switch (eventData.event) {
      case "newMessage":
        onNewMessage(
          eventData,
          communityMemberId,
          chatRoomsData,
          isOpenChatRoom,
        );
        break;
      case "chatRoomRead":
        onChatRoomRead(eventData);
        break;
      case "chatRoomCreated":
        onChatRoomCreated(eventData);
        break;
      case "chatRoomUpdated":
        onChatRoomUpdated(eventData);
        break;
      case "chatRoomParticipantsUpdated":
        onChatRoomParticipantsUpdated(eventData);
        break;
      case "chatRoomParticipantDestroy":
        onChatRoomParticipantDestroy(eventData);
        break;
      case "chatRoomDeleted":
        onChatRoomParticipantDestroy(eventData);
    }
  };

  const onEmbedEventReceive = (eventData, chatRoomUuid) => {
    switch (eventData.event) {
      case "chatRoomRead":
        onChatRoomRead(eventData);
        break;
      case "chatRoomUpdated":
        onChatRoomDetailsUpdated(eventData, chatRoomUuid);
        break;
      case "chatRoomParticipantsUpdated":
        onChatRoomParticipantsUpdated(eventData);
        break;
      case "chatRoomParticipantDestroy":
        onChatRoomParticipantDestroy(eventData);
        break;
    }
  };

  const updateTitleBar = (chatRoomsArr, uuid) => {
    const openChatRoom = fetchChatRoomForUuid(chatRoomsArr, uuid);
    if (openChatRoom) {
      addDirectMessageParticipantNameInTitle(
        openChatRoom.chat_room_kind === DIRECT_CHAT_ROOM
          ? openChatRoom.other_participants_preview[0].name
          : openChatRoom.chat_room_name,
      );
    }
  };

  const updateRichTextMessage = async ({
    chatRoomUuid,
    rich_text_body,
    messageId,
  }) => {
    try {
      await reactQueryPatch(
        internalApi.chatRoomMessages.update({
          uuid: chatRoomUuid,
          id: messageId,
        }),
        {
          chat_room_message: {
            rich_text_body,
          },
        },
      );
    } catch (err) {
      toast.error(t("something_went_wrong"));
      notifyBugsnag(err);
    }
  };

  const deleteRichTextMessage = async ({ chatRoomUuid, messageId }) => {
    try {
      await reactQueryDelete(
        internalApi.chatRoomMessages.delete({
          uuid: chatRoomUuid,
          id: messageId,
        }),
      );
    } catch (err) {
      toast.error(t("something_went_wrong"));
      notifyBugsnag(err);
    }
  };

  const value = {
    chatRooms,
    setChatRooms,
    fetchChatRoomForUuid,
    onEventReceive,
    onEmbedEventReceive,
    updateTitleBar,
    sendRichTextMessage,
    updateRichTextMessage,
    deleteRichTextMessage,
  };

  return (
    <ChatRoomsContext.Provider value={value}>
      {children}
    </ChatRoomsContext.Provider>
  );
};

export const useChatsV2Api = () => useContext(ChatRoomsContext);

ChatRoomsContextProvider.propTypes = {
  children: PropTypes.any.isRequired,
};

export const markChatRoomMessagesAsRead = chatRoomUuid =>
  reactQueryPost(internalApi.chatRooms.markAllAsRead({ uuid: chatRoomUuid }));
