import classnames from "classnames";
import { t } from "i18n-js";
import { Icon } from "@circle-react-shared/Icon";
import { Typography } from "@circle-react-uikit/Typography";
import { MAX_IMAGES } from "../UploadImages/hooks/useMultiImageUpload";

export const MultiImageDropZone = ({
  isDragActive,
  getRootProps,
  getInputProps,
  open,
  className,
}: any) => {
  const translationBase = "image_space.post_modal.steps.upload_images";
  return (
    <div {...getRootProps()} className="group h-full w-full">
      <button
        type="button"
        className={classnames(
          "flex h-full w-full flex-col items-center justify-center px-4 text-center",
          className,
        )}
        onClick={open}
      >
        <input {...getInputProps()} data-testid="upload_multi_image" />
        <Icon
          size={24}
          type="24-circle-arrow-up"
          className={classnames("text-default  !h-6 !w-6", {
            "group-hover:text-messaging-info-blue": !isDragActive,
          })}
        />
        <div className="mb-1 mt-3">
          <Typography.LabelMd
            weight="semibold"
            color={classnames("text-dark", {
              "group-hover:text-messaging-info-blue": !isDragActive,
            })}
          >
            {t(`${translationBase}.drag_and_drop_up_to_n_images`, {
              max_images: MAX_IMAGES,
            })}
            {isDragActive && " " + t(`${translationBase}.here`)}
          </Typography.LabelMd>
        </div>
        {!isDragActive && (
          <Typography.LabelSm color="text-default group-hover:text-messaging-info-blue">
            {t(`${translationBase}.recommendation`)}
          </Typography.LabelSm>
        )}
      </button>
    </div>
  );
};
