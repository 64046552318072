import PropTypes from "prop-types";
import { useChatRoomStore } from "@circle-react/hooks/chatsV2";
import { useChatMessagesV2Api } from "@circle-react/hooks/chatsV2/useChatMessagesV2Api";
import { TwoWayPagination } from "@circle-react-shared/Pagination/TwoWayPagination";

export const ChatRoomBody = ({
  children,
  isLastPage,
  isFirstPage,
  setIsFirstPage,
  setIsLastPage,
  chatRoomUuid,
  componentId = "message-scroll-view",
}) => {
  const { fetchChatMessages } = useChatMessagesV2Api();

  const {
    hasNextPage,
    hasPrevPage,
    isLoading: isChatMessagesLoading,
    firstMessageId,
    lastMessageId,
  } = useChatRoomStore();

  return (
    <div className="flex h-auto grow flex-col overflow-auto">
      <TwoWayPagination
        shouldShowPrevLoader={!isLastPage}
        shouldShowNextLoader={!isFirstPage}
        hasNextPage={hasNextPage}
        hasPrevPage={hasPrevPage}
        onNextPage={messageId => {
          fetchChatMessages({
            chatRoomUuid,
            id: messageId,
            previousPerPage: 0,
            nextPerPage: 20,
          });
          setIsFirstPage(false);
        }}
        onPrevPage={messageId => {
          fetchChatMessages({
            chatRoomUuid,
            id: messageId,
          });
          setIsLastPage(false);
        }}
        firstItemId={firstMessageId}
        lastItemId={lastMessageId}
        componentId={componentId}
        isLoading={isChatMessagesLoading}
      >
        {children}
      </TwoWayPagination>
    </div>
  );
};

ChatRoomBody.propTypes = {
  isLastPage: PropTypes.bool.isRequired,
  isFirstPage: PropTypes.bool.isRequired,
  setIsFirstPage: PropTypes.func.isRequired,
  setIsLastPage: PropTypes.func.isRequired,
  chatRoomUuid: PropTypes.string.isRequired,
  children: PropTypes.node,
};
