import { t } from "i18n-js";
import { useMemberProfileActions } from "@circle-react/components/Profile/useMemberProfileActions";
import { IconButton } from "@circle-react-shared/uikit/HeaderV3/IconButton";
import { Dropdown } from "@circle-react-uikit/Dropdown";

interface MobileProfileDrawerHeaderProps {
  memberPublicId: string;
  member: any;
  drawer: any;
}

export const MobileProfileDrawerHeader = ({
  memberPublicId,
  member,
  drawer,
}: MobileProfileDrawerHeaderProps) => {
  const {
    shouldShowEditProfileButton,
    onClickOpenEditProfileDrawer,
    handleCopyProfileLink,
  } = useMemberProfileActions({
    memberPublicId,
    member,
  });

  return (
    <div className="flex w-full items-center justify-between px-5 py-3">
      <IconButton
        name="20-arrow-left"
        iconSize={20}
        onClick={drawer.hide}
        ariaLabel={t("back")}
      />
      <Dropdown
        size="medium"
        direction="bottom-end"
        button={
          <IconButton
            name="20-menu-dots-horizontal"
            iconSize={20}
            ariaLabel={t("options_dropdown")}
          />
        }
      >
        <Dropdown.ItemLink onClick={handleCopyProfileLink}>
          {t("profile_drawer.dropdown.copy_profile_link")}
        </Dropdown.ItemLink>
        {shouldShowEditProfileButton && (
          <Dropdown.ItemLink onClick={onClickOpenEditProfileDrawer}>
            {t("profile_drawer.dropdown.edit_member_profile")}
          </Dropdown.ItemLink>
        )}
      </Dropdown>
    </div>
  );
};
