import { format, isValid } from "date-fns";
import { t } from "i18n-js";
import { startCase } from "lodash";

export const formatDate = (value: string = "", dateFormat: string = "PP") => {
  if (!value) return "─";
  const date = new Date(value);

  if (!isValid(date)) return "─";

  return startCase(format(date, dateFormat));
};

export const getRole = (roles: any = {}) => {
  if (roles.admin) return t("role_admin");
  if (roles.moderator) return t("moderator");
  return t("member");
};
