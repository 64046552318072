import classNames from "classnames";
import { t } from "i18n-js";
import { isEmpty, noop } from "lodash";
import { useProfileDrawer } from "@/react/components/Drawers/ProfileDrawer";
import { ProfileHoverCard } from "@circle-react/components/Profile/HoverCard";
import { Reactions } from "@circle-react/components/Reactions";
import { usePunditUserContext } from "@circle-react/contexts";
import { useRailbar } from "@circle-react/hooks/chatsV2/useRailbar";
import { Icon, isIconType } from "@circle-react-shared/Icon";
import { UserImage } from "@circle-react-shared/uikit/UserImage";
import { Avatar, Row } from "@circle-react-uikit/SkeletonLoader/components";
import { getRole } from "../helpers";
import { ContentBody } from "./ContentBody";
import { MessageTimestamp } from "./MessageTimestamp";
import { RichTextUpdateBox } from "./RichTextUpdateBox";
import { RoleIcon } from "./RoleIcon";
import "./styles.scss";

export interface MessageBlockProps {
  chatRoom?: any;
  currentParticipant?: any;
  handleSubmit?: (value: any) => void | Promise<void>;
  isEditMessage?: boolean;
  isInitialPage?: boolean;
  isMessageThread?: boolean;
  isModal?: boolean;
  isParentMessage?: boolean;
  isPinned?: boolean;
  isPinnedMessageAtTop?: boolean;
  isRenderedOnHeader?: boolean;
  message: any;
  messageBodyClassName?: string;
  participants?: any[];
  roles?: any;
  chatRoomUrl?: string;
  toggleDeleteConfirmationModal?: () => void;
}

export const MessageBlock = ({
  chatRoom,
  chatRoomUrl,
  currentParticipant = {},
  handleSubmit,
  isEditMessage = false,
  isInitialPage = false,
  isMessageThread = false,
  isModal = false,
  isParentMessage = false,
  isPinned = false,
  isPinnedMessageAtTop = false,
  isRenderedOnHeader = false,
  message,
  messageBodyClassName,
  participants = [],
  roles = {},
  toggleDeleteConfirmationModal = noop,
}: MessageBlockProps) => {
  const memberProfileDrawer = useProfileDrawer();
  const { chat_room_participant_id: participantId } = message;
  const sender = participants.find(
    participant => participant.id === participantId,
  );

  const role = getRole(sender?.community_member_id, roles);
  const isLoadingSender = isEmpty(sender);
  const isMemberProfileAvailable = sender?.user_public_uid;

  const { currentCommunitySettings } = usePunditUserContext();
  const { chat_reactions_enabled: isChatReactionsEnabled } =
    currentCommunitySettings || {};
  const { community_member_id: currentCommunityMemberId } =
    currentParticipant || {};

  const { isLiveStream } = useRailbar();

  const openProfileDrawer = () => {
    if (isMemberProfileAvailable) {
      void memberProfileDrawer.show({
        memberPublicId: sender.user_public_uid,
        params: {
          shouldOpenInNewTab: isLiveStream,
        },
      });
    }
  };

  const renderSenderName = () =>
    sender.id === currentParticipant?.id ? t("messaging.you") : sender.name;

  const renderRemovedOrLeftBadge = () =>
    sender.deleted_at ? t("messaging.removed_or_left") : "";

  return (
    <div
      className={classNames({
        "ml-1 mt-2 flex w-[98%] justify-start py-1.5 pl-5": !isRenderedOnHeader,
        "flex w-full justify-start px-5 py-1.5": isRenderedOnHeader,
      })}
    >
      <button
        type="button"
        className={classNames("mr-3 self-start", {
          "cursor-default": !isMemberProfileAvailable,
        })}
        aria-label={t("messaging.open_member_profile_button_aria_label", {
          name: sender?.name,
        })}
        onClick={openProfileDrawer}
      >
        {isLoadingSender ? (
          <Avatar />
        ) : (
          <ProfileHoverCard
            communityMemberId={sender.community_member_id}
            name={sender.name}
            publicUid={sender.user_public_uid}
            headline={sender.headline}
            avatarURL={sender.avatar_url}
            disabled={!isMemberProfileAvailable}
          >
            <UserImage src={sender.avatar_url} name={sender.name} size="10" />
          </ProfileHoverCard>
        )}
      </button>
      <div
        className={classNames("w-full overflow-x-auto", messageBodyClassName)}
      >
        <div className="mb-px flex items-center gap-2">
          <button
            type="button"
            className={classNames(
              "text-darkest text-left text-sm font-semibold",
              {
                "cursor-default": !isMemberProfileAvailable,
              },
            )}
            onClick={openProfileDrawer}
            data-testid="number-of-replies"
          >
            {isLoadingSender ? (
              <Row md />
            ) : (
              <>
                {renderSenderName()}{" "}
                <span className="font-normal">
                  {renderRemovedOrLeftBadge()}
                </span>
              </>
            )}
          </button>
          {role && isIconType(role) && <RoleIcon role={role} />}
          {isPinned && (
            <Icon
              type="pin"
              aria-label={t("messaging.pinned_message")}
              aria-hidden={false}
            />
          )}
          <MessageTimestamp
            chatRoom={chatRoom}
            message={message}
            chatRoomUrl={chatRoomUrl}
            isParentMessage={isParentMessage}
            isMessageThread={isMessageThread}
            isRenderedOnHeader={isRenderedOnHeader}
          />
        </div>
        {!isModal && isEditMessage ? (
          <RichTextUpdateBox
            message={message}
            onSubmit={handleSubmit}
            onDelete={toggleDeleteConfirmationModal}
            chatRoom={chatRoom}
            isRenderedOnHeader={isRenderedOnHeader}
          />
        ) : (
          <ContentBody
            message={message}
            isInitialPage={isInitialPage}
            isPinnedMessageAtTop={isPinnedMessageAtTop}
          />
        )}
        {isChatReactionsEnabled && (
          <Reactions
            reactions={message.reactions}
            currentCommunityMemberId={currentCommunityMemberId}
            reactionableId={message.id}
            reactionableType="chat_room_message"
          />
        )}
      </div>
    </div>
  );
};
