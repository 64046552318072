import classNames from "classnames";
import { t } from "i18n-js";
import { noop } from "lodash";
import { DeletedMemberMessage } from "@circle-react-shared/ProfileModal";
import { Tab } from "@circle-react-uikit/TabV2";
import { ProfileAbout } from "./About";
import { ProfileComments } from "./Comments";
import { ProfilePosts } from "./Posts";
import { ProfileHeader } from "./ProfileHeader";
import { ProfileSpaces } from "./Spaces";
import "./styles.scss";

const TABS = {
  ABOUT: t("profile_tabs.about"),
  POSTS: t("profile_tabs.posts"),
  COMMENTS: t("profile_tabs.comments"),
  SPACES: t("profile_tabs.spaces"),
};

const TAB_OPTIONS = Object.values(TABS).map(value => ({ label: value }));

interface ProfileProps {
  member: any;
  onClose: () => void;
  onOpenMessaging?: (chatRoomUuid: string) => void;
  memberPublicId: string;
  isFullPageProfile: boolean;
  shouldOpenInNewTab?: boolean;
  shouldHideMessageButton?: boolean;
  shouldHideBadge?: boolean;
  isRenderedOnRailBar?: boolean;
  shouldFixTabsOnTop?: boolean;
}

export const Profile = ({
  member,
  onClose,
  onOpenMessaging,
  memberPublicId,
  isFullPageProfile,
  shouldOpenInNewTab = false,
  shouldHideMessageButton = false,
  shouldHideBadge = false,
  isRenderedOnRailBar = false,
  shouldFixTabsOnTop = false,
}: ProfileProps) => {
  const { comments_count, posts_count, spaces_count } = member;

  const tabOptions = shouldHideBadge
    ? TAB_OPTIONS
    : [
        { label: TABS.ABOUT },
        { label: TABS.POSTS, badge: { value: posts_count } },
        { label: TABS.COMMENTS, badge: { value: comments_count } },
        { label: TABS.SPACES, badge: { value: spaces_count } },
      ];

  const scrollableTarget: any = isFullPageProfile ? "panel" : null;

  const handleSendMessageClick = (chatRoomUuid: string) => {
    if (onOpenMessaging) {
      onOpenMessaging(chatRoomUuid);
    } else {
      onClose();
    }
  };

  return (
    <div
      className={classNames({
        "profile-full-page": isFullPageProfile,
        "profile-drawer trix-v2": !isFullPageProfile,
        "flex h-full": member.is_deleted,
      })}
    >
      {member.is_deleted ? (
        <DeletedMemberMessage />
      ) : (
        <Tab.Group
          className={classNames("flex  flex-col !overflow-hidden", {
            "!h-[calc(100vh-50px)] md:!h-[calc(100vh-60px)]":
              !isFullPageProfile && !isRenderedOnRailBar,
            "!h-[calc(100dvh-24px)] md:!h-[calc(100vh-2.5rem-64px)]":
              isRenderedOnRailBar && !isFullPageProfile,
          })}
        >
          <div
            className={classNames({
              "sticky top-0 z-30": shouldFixTabsOnTop,
            })}
          >
            <ProfileHeader
              member={member}
              onSendMessageClick={
                isFullPageProfile ? noop : handleSendMessageClick
              }
              isFullPageProfile={isFullPageProfile}
              shouldHideMessageButton={shouldHideMessageButton}
              shouldOpenInNewTab={shouldOpenInNewTab}
            />
            <div className="border-primary bg-primary border-b px-6">
              <Tab.List>
                {Object.values(tabOptions).map(tab => (
                  <Tab key={tab.label} className="text-sm">
                    {tab.label}
                    <Tab.Counter>
                      {tab.badge && tab.badge.value > 0 && tab.badge.value}
                    </Tab.Counter>
                  </Tab>
                ))}
              </Tab.List>
            </div>
          </div>
          <Tab.Panels
            className={classNames({
              "h-full grow": !isFullPageProfile,
            })}
          >
            <Tab.Panel>
              <ProfileAbout member={member} />
            </Tab.Panel>
            <Tab.Panel>
              <ProfilePosts
                memberPublicId={memberPublicId}
                onClose={isFullPageProfile ? noop : onClose}
                shouldOpenInNewTab={shouldOpenInNewTab}
                scrollableTarget={scrollableTarget}
              />
            </Tab.Panel>
            <Tab.Panel>
              <ProfileComments
                memberPublicId={memberPublicId}
                member={member}
                onClose={isFullPageProfile ? noop : onClose}
                shouldOpenInNewTab={shouldOpenInNewTab}
                scrollableTarget={scrollableTarget}
              />
            </Tab.Panel>
            <Tab.Panel>
              <ProfileSpaces
                memberPublicId={memberPublicId}
                onClose={isFullPageProfile ? noop : onClose}
                shouldOpenInNewTab={shouldOpenInNewTab}
                scrollableTarget={scrollableTarget}
              />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      )}
    </div>
  );
};

Profile.displayName = "Profile";
