import { t } from "i18n-js";
import { getEventRegistrationUrl } from "@/react/helpers/loginHelpers";
import { userPaths } from "@/react/helpers/urlHelpers";
import { eventApi } from "@circle-react/api/eventApi";
import { useCommunitySignup } from "@circle-react/components/Events/hooks/useCommunitySignup";
import { isPaidEvent } from "@circle-react/components/EventsV3/Modal/utils";
import { usePunditUserContext } from "@circle-react/contexts";
import { useEventRsvpData } from "./useEventRsvpData";

export const useEventRsvp = (event, onChange) => {
  const {
    currentUser,
    error,
    hasEventEnded,
    isConfirmed,
    isLoading,
    isUnconfirmed,
    role,
    setShouldShowConfirmation,
    shouldShowConfirmation,
    setIsLoading,
    rsvpStatus,
    ticketData,
    setShouldShowUnrsvpConfirmation,
    shouldShowUnrsvpConfirmation,
  } = useEventRsvpData(event);

  const { currentCommunityMember } = usePunditUserContext();
  const { canUsersSignUp, userSignUpUrl } = useCommunitySignup();

  const toggleRsvp = async () => {
    if (isLoading) {
      return;
    }

    if (!currentUser || !currentCommunityMember) {
      if (isPaidEvent(event)) {
        window.location.href = event.paywall_attributes.checkout_url;
      } else {
        window.location.href = getEventRegistrationUrl(
          canUsersSignUp,
          userSignUpUrl,
        );
      }
      return;
    }

    const isPaidEventWithoutTicket = isPaidEvent(event) && !rsvpStatus;
    const rsvpMethod =
      isPaidEventWithoutTicket || isUnconfirmed
        ? eventApi.rsvp
        : eventApi.unRsvp;
    setIsLoading(true);
    try {
      const response = await rsvpMethod({ event, params: { role } });
      if (response?.redirect_url) {
        window.location.href = response.redirect_url;
      } else {
        setShouldShowConfirmation(isUnconfirmed);
        onChange({
          ...event,
          rsvped_event: isUnconfirmed,
          rsvp_status: isUnconfirmed ? "yes" : "no",
        });
      }
    } catch (err) {
      error(err.message);
      console.error("useEventRsvp - could not rsvp - ", event?.slug);
    }
    setIsLoading(false);
  };

  const rsvpWhileJoiningEvent = async () => {
    if (isLoading) {
      return;
    }

    if (!currentUser) {
      error(
        t("log_in_to_perform_action", {
          action: t("events.rsvp.rsvp"),
        }),
      );
      window.location.href = userPaths.signIn({
        params: {
          post_login_redirect: window.location.href,
        },
      });
      return;
    }
    const response = await eventApi.rsvp({ event });
    if (response.ok) {
      if (response?.redirect_url) {
        window.location.href = response.redirect_url;
      } else {
        onChange({ ...event, rsvped_event: true });
      }
    }
  };

  const dismissConfirmation = () => setShouldShowConfirmation(false);
  const dismissUnrsvpConfirmation = () =>
    setShouldShowUnrsvpConfirmation(false);

  return {
    toggleRsvp,
    loading: isLoading,
    confirmed: isConfirmed,
    rsvpStatus,
    showConfirmation: shouldShowConfirmation,
    dismissConfirmation,
    dismissUnrsvpConfirmation,
    eventEnded: hasEventEnded,
    rsvpWhileJoiningEvent,
    ticketData,
    setShouldShowUnrsvpConfirmation,
    shouldShowUnrsvpConfirmation,
  };
};
