import { useEffect } from "react";
import classNames from "classnames";
import { t } from "i18n-js";
import { noop } from "lodash";
import { isPaidEvent } from "@circle-react/components/EventsV3/Modal/utils";
import { Typography } from "@circle-react/components/shared/uikit/Typography";
import { dateToTimeString } from "@circle-react/helpers/dateTimeHelpers/getLocalizedDate";
import type { Event } from "@circle-react/types/Event";
import { ActionModal } from "@circle-react-shared/ModalsV2/ActionModal";
import { Form } from "@circle-react-uikit/Form";
import { Loader } from "@circle-react-uikit/Loader";
import { useRecurringFrequencyLabel } from "../../hooks/useRecurringFrequencyLabel";
import { ConfirmationModal } from "../ConfirmationModal/index";
import { useEventRsvpData } from "../hooks/useEventRsvpData";
import { Row } from "./Row";
import { useRecurringRsvpModal } from "./hooks/useRecurringRsvpModal";

interface RecurringRsvpModalProps {
  event: Event;
  eventRsvpedByDefault?: boolean;
  onChange: () => void;
  onClose: () => void;
  show?: boolean;
}

export const RecurringRsvpModal = ({
  event,
  eventRsvpedByDefault = false,
  onChange,
  onClose,
  show = false,
}: RecurringRsvpModalProps) => {
  const translationRoot = "events.rsvp.recurring_modal";
  const {
    isLoading,
    events,
    eventsState,
    eventsSelectedCount,
    fetchEvents,
    onSubmit,
    onCheckboxChange,
    onSelectAllClickHandler,
  } = useRecurringRsvpModal({ event, onClose, onChange, eventRsvpedByDefault });

  const { shouldShowConfirmation, setShouldShowConfirmation } =
    useEventRsvpData(event);

  const onSubmitModal = () => {
    void onSubmit();
    setShouldShowConfirmation(true);
  };

  const onCloseModal = () => {
    onClose();
  };

  const onCloseConfirmationModal = () => {
    setShouldShowConfirmation(false);
  };

  useEffect(() => {
    if (show) {
      fetchEvents();
    }
  }, [show]);

  const {
    event_setting_attributes: eventData,
    recurring_setting_attributes: recurringSetting,
  } = event || {};

  const {
    starts_at: startsAt,
    time_zone_abbreviations: timeZoneAbbreviations,
    time_zone: timeZone,
    start_date: StartDate,
  } = eventData || {};
  const { frequency: recurringFrequency } = recurringSetting || {};
  const { label: recurringFrequencyLabel } = useRecurringFrequencyLabel({
    startsAt: StartDate,
    frequency: recurringFrequency,
    firstLetterCapital: false,
  });

  const dateText = `${dateToTimeString(
    startsAt,
    timeZone,
  )} ${timeZoneAbbreviations}.`;

  return (
    <>
      <ActionModal
        size="md"
        title={event.name}
        actionText={t(`${translationRoot}.rsvp`)}
        actionVariant="primary"
        isOpen={!!show}
        onClose={onCloseModal}
        onAction={onSubmitModal}
        disabled={isLoading || eventsSelectedCount === 0}
      >
        <div className="mt-2.5 text-center text-gray-500">
          <Typography.BodyMd>
            {t(`${translationRoot}.description_line_1`)}{" "}
            {recurringFrequencyLabel} {t(`${translationRoot}.at`)} {dateText}
          </Typography.BodyMd>
          <Typography.BodyMd>
            {t(`${translationRoot}.description_line_2`)}
          </Typography.BodyMd>
        </div>
        {isLoading && <Loader />}
        {eventsState && !isLoading && (
          <Form defaultValues={eventsState} onSubmit={noop}>
            <div className="mt-7 flex items-center justify-between text-gray-500">
              <Typography.LabelSm weight="medium">
                {events?.length} {t(`${translationRoot}.events`)}
              </Typography.LabelSm>
              <button
                type="button"
                className="text-circle"
                onClick={onSelectAllClickHandler}
              >
                <Typography.LabelSm weight="medium">
                  {t(`${translationRoot}.select_all`)}
                </Typography.LabelSm>
              </button>
            </div>
            <div className="mt-4 overflow-auto md:max-h-80">
              <ul
                className={classNames("flex list-none flex-col gap-4", {
                  "pr-4": events?.length > 6,
                })}
              >
                {events.map(
                  ({
                    id,
                    starts_at,
                    start_date,
                    ends_at,
                    time_zone,
                    time_zone_abbreviations,
                  }) => (
                    <Row
                      key={id}
                      id={`${id}`}
                      name={`${id}`}
                      date={start_date}
                      time={`${dateToTimeString(
                        starts_at,
                        time_zone,
                      )} - ${dateToTimeString(
                        ends_at,
                        time_zone,
                      )} ${time_zone_abbreviations}`}
                      value={eventsState[`${id}`]}
                      onChange={onCheckboxChange}
                    />
                  ),
                )}
              </ul>
            </div>
          </Form>
        )}
      </ActionModal>
      {!isPaidEvent(event) && (
        <ConfirmationModal
          event={event}
          shouldShowConfirmationDate
          isRecurring
          eventsCount={eventsSelectedCount}
          show={shouldShowConfirmation}
          onClose={onCloseConfirmationModal}
        />
      )}
    </>
  );
};
