import type { ReactNode } from "react";
import { Icon } from "@circle-react-shared/Icon";
import type { IconProps, IconType } from "@circle-react-shared/Icon";

export interface ProfileInfoContainerProps {
  iconType?: IconType;
  iconProps?: Partial<IconProps>;
  info?: ReactNode;
  dataTestId?: string;
}

export const ProfileInfoContainer = ({
  iconType,
  iconProps = {},
  info,
  dataTestId,
}: ProfileInfoContainerProps) => {
  if (!info) return null;

  return (
    <div
      className="profile-drawer__about__block whitespace-pre-line [word-break:break-word]"
      data-testid={dataTestId}
    >
      {iconType ? (
        <Icon
          useWithFillCurrentColor
          className="text-dark"
          type={iconType}
          {...iconProps}
        />
      ) : null}{" "}
      <p>{info}</p>
    </div>
  );
};
