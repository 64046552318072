import { useCallback, useEffect, useMemo, useState } from "react";
import { has, isArray, some } from "lodash";
import { useHistory, useLocation } from "react-router-dom";
import { posts } from "@/react/helpers/urlHelpers";
import { useCurrentPostHelpers } from "../useCurrentPostHelpers";
import {
  convertNewlinesToBr,
  isMediaContentInEditorEmpty,
  isOtherContentInEditorEmpty,
} from "./utils";

export const MEDIA_NODE_TYPES = ["image", "file", "embed", "poll"];

export const useMinimalTipTapContent = () => {
  const { isTiptapPost, post } = useCurrentPostHelpers();
  const [mediaContent, setMediaContent] = useState<Record<
    string,
    unknown
  > | null>(null);
  const [otherContent, setOtherContent] = useState<Record<
    string,
    unknown
  > | null>(null);
  const location = useLocation();
  const history = useHistory();

  const {
    tiptap_body: tiptapBody,
    truncated_content: truncatedContent,
    slug,
    space_slug: spaceSlug,
  } = post;

  const isMediaContentEmpty = useMemo(
    () => isMediaContentInEditorEmpty(mediaContent),
    [mediaContent],
  );

  const isOtherContentEmpty = useMemo(
    () => isOtherContentInEditorEmpty(otherContent),
    [otherContent],
  );

  const isMediaNode = useCallback(
    (node: any) => MEDIA_NODE_TYPES.includes(node.type),
    [],
  );

  const doesNodeHaveContent = useCallback(
    (node: any) => has(node, "content") && isArray(node.content),
    [],
  );

  const traverseContentNodes = useCallback(
    (nodes: any, callback: any): boolean =>
      some(nodes, (currentNode: any) => {
        if (callback(currentNode)) {
          return true;
        }
        if (doesNodeHaveContent(currentNode)) {
          return traverseContentNodes(currentNode.content, callback);
        }
        return false;
      }),
    [doesNodeHaveContent],
  );

  const findFirstMediaNode = useCallback(
    (nodes: any) => {
      let firstMediaNode = null;
      traverseContentNodes(nodes, (currentNode: any) => {
        if (isMediaNode(currentNode)) {
          firstMediaNode = currentNode;
          return true;
        }
        return false;
      });
      return firstMediaNode;
    },
    [isMediaNode, traverseContentNodes],
  );

  const removeMediaNodes = useCallback(
    (node: any): any => {
      if (isMediaNode(node)) {
        return null;
      }
      if (doesNodeHaveContent(node)) {
        return {
          ...node,
          content: node.content.map(removeMediaNodes).filter(Boolean),
        };
      }
      return node;
    },
    [isMediaNode, doesNodeHaveContent],
  );

  const generateMinimalTipTapContent = useCallback(
    (content: any) => {
      const firstMediaNode = findFirstMediaNode(content);
      const mediaContent: any[] = [];
      if (firstMediaNode) {
        mediaContent.push(firstMediaNode);
      }

      const otherContent = content.map(removeMediaNodes).filter(Boolean);

      return { media: mediaContent, other: otherContent };
    },
    [findFirstMediaNode, removeMediaNodes],
  );

  useEffect(() => {
    if (isTiptapPost && tiptapBody) {
      const content = tiptapBody?.body?.content;
      const { media, other } = generateMinimalTipTapContent(content);
      setMediaContent({
        type: "doc",
        content: media,
      });
      setOtherContent({
        type: "doc",
        content: other,
      });
    }
  }, [isTiptapPost, tiptapBody, generateMinimalTipTapContent]);

  const redirectToPostModal = useCallback(() => {
    history.push({
      pathname: posts.show({
        slug,
        spaceSlug,
      }),
      state: {
        prevLocation: location,
      },
    });
  }, [history, location, slug, spaceSlug]);

  const formattedTruncatedContent = useMemo(
    () => (truncatedContent ? convertNewlinesToBr(truncatedContent) : ""),
    [truncatedContent],
  );

  return {
    mediaContent,
    otherContent,
    truncatedContent,
    formattedTruncatedContent,
    isMediaContentEmpty,
    isOtherContentEmpty,
    tiptapBody,
    redirectToPostModal,
  };
};
