import { useEffect } from "react";
import classNames from "classnames";
import InnerHTML from "dangerously-set-html-content";
import { isFunction } from "lodash";
import { isImagePost } from "@/react/helpers/postHelpers";
import { posts } from "@/react/helpers/urlHelpers";
import { useCurrentPostHelpers } from "@circle-react/components/PostsV3/useCurrentPostHelpers";
import { useCurrentPostContext } from "@circle-react/contexts";
import { useBackendProps } from "@circle-react/providers";
import { DisplayTrixContent } from "@circle-react-uikit/DisplayTrixContent";
import type { LineClampLevel } from "@circle-react-uikit/SeeMoreLess";
import { SeeMoreLess } from "@circle-react-uikit/SeeMoreLess";
import { TipTapBlockEditor } from "@circle-react-uikit/TipTapBlockEditor";
import { KnowledgeBaseFeedbackSection } from "../KnowledgeBaseFeedbackSection";

export const PostBody = ({ enableTruncation = false, noPadding = false }) => {
  const {
    post,
    isSharedPostWithTeaserLayout,
    isSharedPostViewWithMinimalLayout,
    isTiptapPost,
    isTrixPost,
    isBasicPost,
    isPostBodyEmpty,
  } = useCurrentPostHelpers();
  const { minimal_layout: isMinimalLayout } = useBackendProps();
  const { usedIn } = useCurrentPostContext();

  const isKnowledgeBasePost =
    ["help", "help-en"].includes(post.community_slug) && isBasicPost;

  const isDisabled = !enableTruncation;

  const isMinimalTeaser =
    isSharedPostViewWithMinimalLayout && isSharedPostWithTeaserLayout;

  const shouldHideToggle = isSharedPostWithTeaserLayout;

  const {
    body_trix_content: bodyTrixContent,
    tiptap_body: tiptapBody,
    internal_custom_html: html,
    pinned_at_top_of_space: isPinned,
    updated_at: updatedAt,
  } = post;

  useEffect(() => {
    if (html && isFunction(window?.loadOembed)) {
      window.loadOembed();
    }
  }, [html]);

  if (isPostBodyEmpty) {
    return null;
  }

  let lines: LineClampLevel = 8;
  if (isPinned) {
    lines = 2;
  } else if (isMinimalTeaser) {
    lines = 4;
  }

  return (
    <div
      key={updatedAt}
      className={classNames("post__body", {
        "!p-0": noPadding,
        "px-5 pb-5 md:px-6": isImagePost(post),
        "mt-5": usedIn === "minimal-post-modal",
      })}
      data-testid="post-body"
    >
      <div
        className="post__inside trix-v2 w-full"
        data-testid="post-body-inside"
      >
        <SeeMoreLess
          lines={lines}
          disabled={isDisabled}
          hideToggle={shouldHideToggle}
        >
          {isTrixPost && bodyTrixContent && (
            <DisplayTrixContent
              content={bodyTrixContent}
              contentParentPath={posts.show({
                slug: post.slug,
                spaceSlug: post.space_slug,
              })}
            />
          )}
          {isTiptapPost && tiptapBody && (
            <TipTapBlockEditor
              readOnly
              disableMentionClicks={isMinimalLayout}
              value={tiptapBody.body}
              sgidToObjectMap={tiptapBody.sgids_to_object_map}
              inlineAttachments={tiptapBody.inline_attachments}
              disabledExtensions={["cta"]}
            />
          )}
          {html && <InnerHTML key="internal_custom_html" html={html} />}
          {isKnowledgeBasePost && <KnowledgeBaseFeedbackSection post={post} />}
        </SeeMoreLess>
      </div>
    </div>
  );
};
