import { Icon } from "@circle-react/components/shared/Icon";
import { dateComponents } from "@circle-react/helpers/dateTimeHelpers/dateComponents";
import { dateToTimeString } from "@circle-react/helpers/dateTimeHelpers/getLocalizedDate";
import type { Event } from "@circle-react/types/Event";
import { AddToCalendar } from "@circle-react-uikit/AddToCalendar";
import { addToCalendarHelper } from "../../helpers/addToCalendarHelper";

interface ConfirmationDateProps {
  event: Event;
  label: string;
}

export const ConfirmationDate = ({ event, label }: ConfirmationDateProps) => {
  const { event_setting_attributes: eventData } = event || {};

  const {
    starts_at: startsAt,
    ends_at: endsAt,
    time_zone: timeZone,
  } = eventData || {};

  const { dayOfMonth, shortMonthString } = dateComponents(startsAt);
  const startTime = dateToTimeString(startsAt, timeZone);
  const endTime = dateToTimeString(endsAt, timeZone);

  return (
    <div className="event-confirmation__date">
      <div className="event-confirmation__date-picker">
        <Icon type="calendar" className="icon" />
        {`${dayOfMonth} ${shortMonthString}, ${startTime} - ${endTime}`}
      </div>
      <div className="event-confirmation__date-button">
        <AddToCalendar {...addToCalendarHelper(event, label)} />
      </div>
    </div>
  );
};
