import classnames from "classnames";
import { t } from "i18n-js";
import { truncate } from "lodash";
import { PostActionsDropdownMenu } from "@/react/components/PostsV3/PostViewListItem/PostActionsDropdownMenu";
import { ShowPageLink } from "@/react/components/PostsV3/PostViewListItem/ShowPageLink";
import { useCurrentPostContext } from "@circle-react/contexts";
import { useProfileLinkProps } from "@circle-react/hooks/profile/useProfileLinkProps";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import { useSmScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { BadgeV2 } from "@circle-react-shared/uikit/BadgeV2";
import { UserImage } from "@circle-react-shared/uikit/UserImage";
import { EngagementActions } from "./EngagementActions";
import { LastRepliedOrPosted } from "./LastRepliedOrPosted";

export interface MainContentProps {
  onPostChange: (post: any, options?: any) => void;
  onPostDestroy: (post: any) => void;
  refetchPosts: () => void;
  hideTitle?: boolean;
}

export const MainContent = ({
  onPostChange,
  onPostDestroy,
  refetchPosts,
  hideTitle = false,
}: MainContentProps) => {
  const { record: post } = useCurrentPostContext();
  const isSmScreen = useSmScreenMediaQuery();
  const { isV3Enabled } = useIsV3();

  const {
    flagged_for_approval_at,
    community_member,
    hide_meta_info,
    display_title,
  } = post;
  const containerClassNames = classnames(
    "post--list post--parent border-t border-primary px-5 py-3 md:py-4 bg-primary border-b-none last:border-b md:border-x",
    {
      "flagged-for-approval": flagged_for_approval_at,
      "first:md:rounded-t-lg last:md:rounded-b-lg": !isV3Enabled,
      "first:md:rounded-t-2xl last:md:rounded-b-2xl": isV3Enabled,
    },
  );

  const onChange = (updates = {}, options = {}) => {
    onPostChange({ ...post, ...updates }, options);
  };

  const profileLinkProps = useProfileLinkProps({
    public_uid: community_member.public_uid,
    params: { show_back_link: true },
  });

  if (isSmScreen) {
    return (
      <div className={containerClassNames}>
        <div className="post--list__post text-dark flex gap-3">
          <div className="post--list__body flex w-full flex-col gap-2">
            <div className="post--list__content w-full gap-2 !pr-0 md:gap-0">
              {!hideTitle && (
                <div
                  className={classnames(
                    "post--list__header flex w-full items-start justify-between",
                    {
                      "post-meta--hidden": hide_meta_info,
                    },
                  )}
                >
                  <h2 className="mr-4 space-x-2 break-words text-base font-medium leading-5">
                    <ShowPageLink
                      post={post}
                      className="!text-dark focus-visible:outline-secondary focus-visible:text-dark focus-visible:rounded-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                      shouldOpenPostModal
                    >
                      {truncate(display_title, { length: 110 })}
                    </ShowPageLink>
                    {!!flagged_for_approval_at && (
                      <BadgeV2
                        className="post--list__headline-tag !bg-author-in-review w-full max-w-fit max-w-fit border-0 align-text-top uppercase text-white"
                        label={t("in_review")}
                      />
                    )}
                  </h2>
                  <PostActionsDropdownMenu
                    onPostDestroy={onPostDestroy}
                    refetchPosts={refetchPosts}
                    onChange={onChange}
                  />
                </div>
              )}
            </div>
            {!hide_meta_info && (
              <div className="flex items-start justify-start space-x-2">
                <div className="post--list__user">
                  <a
                    {...profileLinkProps}
                    className="focus-visible:outline-secondary focus-visible:text-dark focus-visible:rounded-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                  >
                    <UserImage
                      src={community_member.avatar_url}
                      name={community_member.name}
                      size="6"
                    />
                  </a>
                </div>
                <div className="post--list__meta flex w-full items-center justify-between">
                  <LastRepliedOrPosted post={post} />
                  <div className="post--list__actions-wrapper gap-2 self-start md:gap-0">
                    <EngagementActions />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={containerClassNames}>
      <div className="post--list__post text-dark flex gap-3">
        {!hide_meta_info && (
          <div className="post--list__user">
            <a
              {...profileLinkProps}
              className="focus-visible:outline-secondary focus-visible:text-dark focus-visible:rounded-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
            >
              <UserImage
                src={community_member.avatar_url}
                name={community_member.name}
                size="10"
              />
            </a>
          </div>
        )}
        <div className="post--list__body flex w-full items-center justify-between">
          <div
            className={classnames("post--list__content flex flex-col", {
              "justify-center": hide_meta_info,
              "justify-start gap-1": !hide_meta_info,
            })}
          >
            {!hideTitle && (
              <div
                className={classnames("post--list__header", {
                  "post-meta--hidden": hide_meta_info,
                })}
              >
                <h2 className="mr-4 flex space-x-2 break-words text-base font-medium leading-5">
                  <ShowPageLink
                    post={post}
                    className="!text-dark focus-visible:outline-secondary focus-visible:text-dark line-clamp-1 focus-visible:rounded-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
                    shouldOpenPostModal
                  >
                    {display_title}
                  </ShowPageLink>
                  {!!flagged_for_approval_at && (
                    <BadgeV2
                      className="post--list__headline-tag !bg-author-in-review w-full max-w-fit max-w-fit shrink-0 border-0 align-text-top uppercase text-white"
                      label={t("in_review")}
                    />
                  )}
                </h2>
              </div>
            )}
            {!hide_meta_info && (
              <div className="post--list__meta inline-flex">
                <LastRepliedOrPosted post={post} />
              </div>
            )}
          </div>
          <div className="post--list__actions-wrapper flex items-baseline gap-1">
            <EngagementActions />
            <PostActionsDropdownMenu
              onPostDestroy={onPostDestroy}
              refetchPosts={refetchPosts}
              onChange={onChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
