import { useMemo } from "react";
import { detectBreakpoints } from "../detectBreakpoints";
import { useChatRoomContext } from "./useChatRoomContext";
import { useChatRoomStore } from "./useChatStore";

export const useScrollInfo = () => {
  const { setShouldShowLatestMessageBtn } = useChatRoomStore();
  const { chatRoomUuid, isMessaging } = useChatRoomContext();
  const { isMobileView } = detectBreakpoints();
  const scrollerId = useMemo(
    () =>
      isMessaging && chatRoomUuid
        ? `message-scroll-view-${chatRoomUuid}`
        : "message-scroll-view",
    [chatRoomUuid, isMessaging],
  );

  const getScrollingElement = () => document.getElementById(scrollerId);

  // Based on the event we will update the action to be performed on scroll once updated chat messages are rendered.
  const calculateScrollInfo = ({ event }: { event: string }) => {
    const element = getScrollingElement();
    if (!element) return {};

    let action = "noScroll";

    switch (event) {
      case "messageSent":
        action = "scrollToBottom";
        setShouldShowLatestMessageBtn(false);
        break;
      case "messageReceived": {
        const distanceFromBottom =
          element.scrollTop - element.scrollHeight + element.offsetHeight;

        const isMobileBottom = isMobileView() && distanceFromBottom === 0;
        const isBottomRange =
          distanceFromBottom >= -(element.offsetHeight * 0.8) ||
          distanceFromBottom > 14;

        if (isMobileBottom || isBottomRange) {
          action = "scrollToBottom";
          setShouldShowLatestMessageBtn(false);
        } else {
          setShouldShowLatestMessageBtn(true);
        }
        break;
      }
      case "chatRoomLoad":
        action = "scrollToUnreadMessageIndicator";
        break;
      case "paginatedMessageLoad":
        action = "adjustTop";
        break;
      case "nextPaginatedMessageLoad":
        action = "noScroll";
        break;
      case "messageUpdated":
        action = "noScroll";
        break;
      case "messageDeleted":
        action = "noScroll";
        break;
      case "parentMessageUpdated":
        action = "noScroll";
        break;
      case "reaction": {
        const distanceFromBottom =
          element.scrollTop - element.scrollHeight + element.offsetHeight;
        const isCloseToTheBottom = distanceFromBottom >= -80;
        if (isCloseToTheBottom) action = "scrollToBottom";
        break;
      }
    }

    return {
      action: action,
      scrollTopValue: element.scrollTop,
      scrollHeightValue: element.scrollHeight,
    };
  };

  return { calculateScrollInfo, scrollerId, getScrollingElement };
};
