import classNames from "classnames";
import { t } from "i18n-js";
import { truncate } from "lodash";
import { useCurrentPostContext } from "@circle-react/contexts";
import {
  dateStringToTimeAgo,
  dateToTimeAgoFormats,
} from "@circle-react/helpers/dateTimeHelpers/timeAgo";
import { getDetailedTimestamp } from "@circle-react/helpers/postHelpers";
import { useSmScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { BadgeV2 } from "@circle-react-shared/uikit/BadgeV2";

export const MemberHeadline = ({ comment, commentLinkProps }: any) => {
  const { community_member: communityMember, created_at } = comment;
  const isSmScreen = useSmScreenMediaQuery();
  const { usedIn } = useCurrentPostContext();
  const isUsedInFullPostImageSpaceModal = usedIn === "full-post-modal";
  const authorHeadline =
    isSmScreen || isUsedInFullPostImageSpaceModal
      ? truncate(communityMember.headline, { length: 60 })
      : communityMember.headline;

  if (!authorHeadline && !isSmScreen && !isUsedInFullPostImageSpaceModal)
    return null;

  return (
    <div className="post__meta flex w-full">
      <TippyV2
        content={communityMember.headline}
        shouldUseSpanWrapper={false}
        interactive={false}
        disabled={!authorHeadline}
      >
        <button
          type="button"
          className="author__credentials text-default focus-visible:outline-secondary focus-visible:text-dark mt-1 w-full w-full text-left text-xs leading-4 focus-visible:rounded-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
        >
          <span>
            {authorHeadline && (
              <span
                className={classNames("overflow-anywhere mr-2", {
                  "md:line-clamp-1": !isUsedInFullPostImageSpaceModal,
                })}
              >
                {authorHeadline}
              </span>
            )}
            {(isSmScreen || isUsedInFullPostImageSpaceModal) &&
              !!authorHeadline && (
                <span className="bg-badge mr-2 inline-block h-[0.15rem] w-[0.15rem] rounded-full align-middle" />
              )}
            {(isSmScreen || isUsedInFullPostImageSpaceModal) && (
              <a
                className={classNames(
                  "text-default hover:text-dark text-xs leading-5",
                  "focus-visible:outline-secondary focus-visible:text-dark mr-2 focus-visible:rounded-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2",
                )}
                {...commentLinkProps}
              >
                <TippyV2
                  content={getDetailedTimestamp(created_at)}
                  className="inline-flex"
                  interactive={false}
                >
                  {dateStringToTimeAgo(created_at, {
                    format: dateToTimeAgoFormats.short,
                  })}
                </TippyV2>
              </a>
            )}
            {(isSmScreen || isUsedInFullPostImageSpaceModal) &&
              comment.flagged_for_approval_at && (
                <BadgeV2
                  className="authour__in-review !bg-author-in-review border-0 uppercase text-white"
                  label={t("comments.in_review")}
                />
              )}
          </span>
        </button>
      </TippyV2>
    </div>
  );
};
