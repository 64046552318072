import { t } from "@circle-react/custom_i18n";
import { useShowProfileEdit } from "@circle-react/hooks/profile/useShowProfileEdit";
import type { CommunityMember } from "@circle-react/types";
import { UserImage } from "@circle-react-shared/uikit/UserImage";
import { Typography } from "@circle-react-uikit/Typography";

interface NameCellProps {
  member: CommunityMember;
  isAvatarHidden?: boolean;
}

export const NameCell = ({
  member,
  isAvatarHidden = false,
}: Readonly<NameCellProps>) => {
  const { showMemberProfileEdit } = useShowProfileEdit();
  const { name, avatar_url, public_uid } = member;

  if (!name) {
    return <>{t("dash")}</>;
  }

  return (
    <button
      className="flex items-center gap-3 text-left"
      type="button"
      onClick={() => showMemberProfileEdit({ memberPublicId: public_uid })}
    >
      {!isAvatarHidden && <UserImage src={avatar_url} name={name} size="6" />}
      <Typography.LabelSm weight="medium">{name}</Typography.LabelSm>
    </button>
  );
};
