import { useMemo } from "react";
import { flatten, uniqBy } from "lodash";
import { useInfiniteQuery, useQueryClient } from "react-query";
import { reactQueryGet } from "@/react/helpers/backendRequestHelpers";
import type { ChatRoom } from "@circle-react/components/Modals/MessagingModal/LeftSidebar/List/ChatRoomTypes";
import { internalApi } from "@circle-react/helpers/urlHelpers";

const DEFAULT_PER_PAGE = 20;
const QUERY_KEY = ["sidebar", "chat-rooms", "all"];
export const MESSAGING_MODAL_QUERY_KEY = ["messaging", "chat-rooms", "all"];
const UNREAD_CHAT_ROOMS_QUERY_KEY = ["sidebar", "chat-rooms", "unread"];

interface Response {
  records: ChatRoom[];
  has_next_page: boolean;
  page: number;
}

interface Props {
  openChatRoomUuid?: string | null;
  uuids?: Array<string>;
}

export const useChatsSidebarApi = ({ openChatRoomUuid, uuids }: Props) => {
  const queryClient = useQueryClient();

  const queryKey = useMemo(() => {
    if (uuids) {
      return UNREAD_CHAT_ROOMS_QUERY_KEY;
    }
    return openChatRoomUuid ? MESSAGING_MODAL_QUERY_KEY : QUERY_KEY;
  }, [uuids, openChatRoomUuid]);

  const updateQueryCache = (newData: any) => {
    queryClient.setQueryData(queryKey, (oldData: any) => ({
      ...oldData,
      pages: oldData.pages.map((page: any, index: any) => ({
        ...(page || {}),
        records: newData.slice(
          DEFAULT_PER_PAGE * index,
          DEFAULT_PER_PAGE * (Number(index) + 1),
        ),
      })),
    }));
  };

  const fetchChatRooms = (page: any) => {
    const params: any = {
      page: page,
      per_page: DEFAULT_PER_PAGE,
    };
    if (uuids) {
      params["uuids"] = uuids;
    }
    return reactQueryGet(
      internalApi.chatRooms.index({
        params,
      }),
    );
  };

  const {
    isLoading,
    refetch: fetchUserChatRooms,
    data: { pages: chatRooms = [] } = {},
    hasNextPage = false,
    fetchNextPage,
  } = useInfiniteQuery<Response, unknown, ChatRoom>(
    queryKey,
    ({ pageParam = 1 }) => fetchChatRooms(pageParam),
    {
      select: ({ pages = [] }) =>
        // RQ recommends to restructure your data that it still includes data.pages and data.pageParams properties - https://tanstack.com/query/v4/docs/react/guides/infinite-queries
        ({
          pages: uniqBy(flatten(pages.map(page => page.records)), "uuid"),
          pageParams: pages.map(page => page.page),
        }),
      onSuccess: () => {
        appendOpenChatRoomIfNotPresent(openChatRoomUuid);
      },
      getNextPageParam: lastPage =>
        lastPage.has_next_page ? Number(lastPage.page) + 1 : undefined,
    },
  );

  const fetchChatRoomForUuid = (uuid: any) =>
    chatRooms.find(chatRoom => chatRoom.uuid === uuid);

  const appendChatRoom = (chatRoomUuid: any) => {
    void reactQueryGet(internalApi.chatRooms.show({ uuid: chatRoomUuid })).then(
      (data: any) => {
        if (data.chat_room) updateQueryCache([data.chat_room, ...chatRooms]);
      },
    );
  };

  const appendOpenChatRoomIfNotPresent = (openChatRoomUuid: any) => {
    if (openChatRoomUuid && openChatRoomUuid !== "new") {
      const openChatRoom = chatRooms.find(({ isOpen }) => Boolean(isOpen));
      if (openChatRoom) appendChatRoom(openChatRoomUuid);
    }
  };

  const updateChatRooms = (updatedChatRoom: ChatRoom) => {
    if (updatedChatRoom.pinned_at) {
      const updatedChatRooms = chatRooms.map(chatRoom => {
        if (chatRoom.uuid === updatedChatRoom.uuid) {
          return updatedChatRoom;
        }
        return chatRoom;
      });

      updateQueryCache(updatedChatRooms);
    } else {
      const pinnedChatRooms: ChatRoom[] = [];
      const remainingChatRooms: ChatRoom[] = [];
      chatRooms.forEach(chatRoom => {
        if (chatRoom.pinned_at) {
          pinnedChatRooms.push(chatRoom);
        } else if (chatRoom.uuid !== updatedChatRoom.uuid) {
          remainingChatRooms.push(chatRoom);
        }
      });

      updateQueryCache([
        ...pinnedChatRooms,
        updatedChatRoom,
        ...remainingChatRooms,
      ]);
    }
  };

  const onNewMessage = (eventData: any, communityMemberId: any) => {
    const latestChatRoomMessage = eventData.json_message;
    const { chat_room_uuid, sender, embedded } = latestChatRoomMessage;
    const { community_member_id } = sender;
    const messageChatRoom = fetchChatRoomForUuid(chat_room_uuid);

    if (messageChatRoom) {
      const { isOpen, unread_messages_count } = messageChatRoom;

      //community_member_id: sender of the message
      //communityMemberId: current logged in community member
      const updatedUnreadMessageCount = isOpen
        ? 0
        : community_member_id === communityMemberId
        ? unread_messages_count
        : Number(unread_messages_count) + 1;

      const updatedChatRoom = {
        ...messageChatRoom,
        last_message: latestChatRoomMessage,
        unread_messages_count: updatedUnreadMessageCount,
      };

      updateChatRooms(updatedChatRoom);
    } else {
      // The message is received in the chat room which is not present in the chatRooms state.
      // So we need to fetch the chat room data from backend and add it to chatRooms
      if (embedded !== true) {
        appendChatRoom(chat_room_uuid);
      }
    }
  };

  const onChatRoomRead = (eventData: any) => {
    const { chat_room_uuid } = eventData;
    const chatRoomIndex = chatRooms.findIndex(
      chatRoom => chatRoom.uuid === chat_room_uuid,
    );
    if (chatRoomIndex > -1) {
      const messageChatRoom = chatRooms[chatRoomIndex];
      const updatedChatRooms = [
        ...chatRooms.slice(0, chatRoomIndex),
        { ...messageChatRoom, unread_messages_count: 0 },
        ...chatRooms.slice(Number(chatRoomIndex) + 1),
      ];
      updateQueryCache(updatedChatRooms);
    }
  };

  const onChatRoomUpdated = (eventData: any) => {
    const chat_room =
      typeof eventData.chat_room === "string"
        ? JSON.parse(eventData.chat_room)
        : eventData.chat_room;
    const updatedChatRooms = chatRooms.map(chatRoom => {
      if (chatRoom.uuid === chat_room.uuid) {
        return {
          ...chatRoom,
          chat_room_name: chat_room.chat_room_name,
        };
      }
      return chatRoom;
    });
    updateQueryCache(updatedChatRooms);
  };

  const onChatRoomParticipantDestroy = (eventData: any) => {
    const { chat_room_uuid } = eventData;
    const updatedChatRooms = chatRooms.filter(
      chatRoom => chatRoom.uuid !== chat_room_uuid,
    );
    updateQueryCache(updatedChatRooms);
  };

  const onChatRoomParticipantsUpdated = (eventData: any) => {
    const updatedChatRoom = JSON.parse(eventData.chat_room);
    const updatedChatRooms = chatRooms.map((chatRoom: any) => {
      if (chatRoom.uuid === updatedChatRoom.uuid) {
        return updatedChatRoom;
      }
      return chatRoom;
    });
    updateQueryCache(updatedChatRooms);
  };

  const onChatRoomCreated = async () => {
    await queryClient.invalidateQueries(queryKey);
  };

  const onChatRoomPinned = (eventData: any) => {
    const { chat_room_id: pinnedChatRoomId, pinned_at: pinnedAt } =
      eventData || {};
    let pinnedChatRoom = {};
    const otherChatRooms: any[] = [];
    chatRooms.forEach(chatRoom => {
      if (chatRoom.id !== pinnedChatRoomId) {
        otherChatRooms.push(chatRoom);
      } else {
        pinnedChatRoom = { ...chatRoom, pinned_at: pinnedAt };
      }
    });
    updateQueryCache([pinnedChatRoom, ...otherChatRooms]);
  };

  const onChatRoomUnpinned = (eventData: any) => {
    const { chat_room_id: unPinnedChatRoomId } = eventData || {};

    const updatedChatRooms: any[] = chatRooms.map(chatRoom => {
      if (chatRoom.id === unPinnedChatRoomId) {
        return { ...chatRoom, pinned_at: null };
      }
      return chatRoom;
    });
    updateQueryCache([...updatedChatRooms]);
    void fetchUserChatRooms(); //Reload chat rooms to get back to the original position
  };

  const onEventReceive = (eventData: any, communityMemberId: any) => {
    switch (eventData.event) {
      case "newMessage":
        onNewMessage(eventData, communityMemberId);
        break;
      case "chatRoomRead":
        onChatRoomRead(eventData);
        break;
      case "chatRoomCreated":
        void onChatRoomCreated();
        break;
      case "chatRoomUpdated":
        onChatRoomUpdated(eventData);
        break;
      case "chatRoomDeleted":
        onChatRoomParticipantDestroy(eventData);
        break;
      case "chatRoomParticipantsUpdated":
        onChatRoomParticipantsUpdated(eventData);
        break;
      case "chatRoomParticipantDestroy":
        onChatRoomParticipantDestroy(eventData);
        break;
      case "chatRoomPinned":
        void onChatRoomPinned(eventData);
        break;
      case "chatRoomUnPinned":
        void onChatRoomUnpinned(eventData);
        break;
    }
  };

  return {
    chatRooms,
    setChatRooms: updateQueryCache,
    fetchChatRoomForUuid,
    onEventReceive,
    chatRoomsHasNextPage: hasNextPage,
    hasNextPage,
    fetchNextPage,
    fetchUserChatRooms,
    isLoading,
  };
};
