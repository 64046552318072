import classnames from "classnames";
import classNames from "classnames";
import { EngagementActions } from "@/react/components/PostsV2/ListViewItem/EngagementActions";
import { EngagementActions as EngagementActionsV3 } from "@/react/components/PostsV3/ListViewItem/EngagementActions";
import { useFullPostModal } from "@/react/components/Spaces/ImageSpace/FullPostModal";
import { isPostMetaHidden } from "@/react/helpers/postHelpers";
import { useAnyHoverMediaQuery } from "@/react/hooks/useMediaQuery";
import { useThemeContext } from "@/react/providers";
import type { ImagePost } from "@/react/types";
import {
  useCurrentSpaceContext,
  usePunditUserContext,
} from "@circle-react/contexts";
import { useProfileLinkProps } from "@circle-react/hooks/profile/useProfileLinkProps";
import { UserImage } from "@circle-react-shared/uikit/UserImage";
import { Typography } from "@circle-react-uikit/Typography";

interface PostFooterProps {
  post: ImagePost;
  onPostChange: (post: ImagePost) => void;
  onPostDestroy: (postId: number) => void;
}
export const PostFooter = ({
  post,
  onPostChange,
  onPostDestroy,
}: PostFooterProps) => {
  const profileLinkProps = useProfileLinkProps({
    public_uid: post.community_member.public_uid ?? "",
    params: { show_back_link: true },
  });
  const { isDarkAppearance } = useThemeContext();
  const canHover = useAnyHoverMediaQuery();
  const fullPostModal = useFullPostModal();
  const { data: space } = useCurrentSpaceContext();
  const bgColor = isDarkAppearance
    ? "bg-[#2B2E33CC] backdrop-blur-[1px]" // bg-primary in dark mode, with 80% opacity and blur
    : "bg-[#FFFFFFCC] backdrop-blur-[1px]"; // bg-primary in light mode, with 80% opacity and blur
  const onCommentsClick = (event: any) => {
    event.preventDefault();
    void fullPostModal.show({
      post,
      spaceId: space?.id,
      onPostChange,
      onPostDestroy,
      autofocusReplyForm: true,
    });
  };
  const { currentCommunitySettings } = usePunditUserContext();
  const isTailwindPostsEnabled =
    currentCommunitySettings?.tailwind_posts_enabled;
  const VersionedEngagementActions = isTailwindPostsEnabled
    ? EngagementActionsV3
    : EngagementActions;

  return (
    <div
      className={classnames(
        "mt-2 flex w-full flex-row-reverse items-center justify-between gap-2",
        {
          "absolute bottom-2 mt-0 hidden px-2 focus:flex focus-visible:flex group-hover:flex peer-hover:flex peer-focus-visible:flex":
            canHover,
        },
      )}
    >
      <div className="flex shrink-0 items-center justify-end gap-2">
        <VersionedEngagementActions
          type="list"
          wrapperClassName="!mr-0"
          commentButtonClassName={classNames("rounded-md p-1", {
            [bgColor]: canHover,
            "pr-0": !isTailwindPostsEnabled,
          })}
          commentIconClassName="text-selector-active md:!mr-0"
          likeButtonClassName={classNames("rounded-md p-1", {
            [bgColor]: canHover,
            "pr-0": !isTailwindPostsEnabled,
          })}
          likeIconClassName="text-selector-active md:!mr-0"
          likedSpanClassName="text-brand"
          showLikesCount={!canHover}
          showCommentsCount={!canHover}
          {...(canHover && { onCommentsClick })}
        />
      </div>
      {!isPostMetaHidden(post) && (
        <a
          {...profileLinkProps}
          className={classnames(
            "flex items-center justify-start gap-2 overflow-hidden rounded-md py-1 pl-1 pr-2",
            { [bgColor]: canHover },
          )}
        >
          <UserImage
            src={post.community_member.avatar_url || ""}
            name={post.community_member.name || ""}
            size="5"
          />
          <Typography.LabelSm weight="medium" truncate>
            {post.community_member.name}
          </Typography.LabelSm>
        </a>
      )}
    </div>
  );
};
