import { upperFirst } from "lodash";
import { getCurrentBcp47Locale } from "@circle-react/helpers/localeHelpers";

export const dateComponents = (dateString: string, timeZone?: string) => {
  let dayOfMonth: number | undefined;
  let shortMonthString: string | undefined;

  try {
    const date = new Date(dateString);
    const locale = getCurrentBcp47Locale();

    const formatter = new Intl.DateTimeFormat(locale, {
      month: "short",
      timeZone,
    });

    const formatedDate = formatter.format(date);

    dayOfMonth = date.getDate();
    shortMonthString = upperFirst(formatedDate).replace(".", "");
  } catch (error) {
    if (error instanceof Error) {
      console.error(`dateComponents - Could not parse date: ${error.message}`);
    }
  }

  return {
    dayOfMonth,
    shortMonthString,
  };
};
