import type { StoreApi, UseBoundStore } from "zustand";
import { create } from "zustand";
import { immer } from "zustand/middleware/immer";
import { useChatRoomContext } from "./useChatRoomContext";

interface ChatRoomState {
  chatRoomMessages: any[];
  isLoading: boolean;
  isInitialPageLoaded: boolean;
  hasNextPage: boolean;
  hasPrevPage: boolean;
  scrollInfo: any;
  firstMessageId: number | null;
  lastMessageId: number | null;
  shouldShowLatestMessageBtn: boolean;
  topMessageId: number | null;
  activeMessageId: string | null;
}

interface ChatRoomActions {
  setChatRoomMessages: (messages: any[]) => void;
  setIsLoading: (isLoading: boolean) => void;
  setIsInitialPageLoaded: (isLoading: boolean) => void;
  setHasNextPage: (hasNextPage: boolean) => void;
  setHasPrevPage: (hasPrevPage: boolean) => void;
  setScrollInfo: (scrollInfo: any) => void;
  setFirstMessageId: (id: number | null) => void;
  setLastMessageId: (id: number | null) => void;
  setShouldShowLatestMessageBtn: (shouldShowLatestMessageBtn: boolean) => void;
  setTopMessageId: (id: number | null) => void;
  setActiveMessageId: (id: string | null) => void;
  resetChatRoom: () => void;
}

type ChatRoomStore = ChatRoomState & ChatRoomActions;

const initialChatRoomState: ChatRoomState = {
  chatRoomMessages: [],
  isLoading: false,
  hasNextPage: false,
  hasPrevPage: false,
  scrollInfo: {},
  firstMessageId: null,
  lastMessageId: null,
  shouldShowLatestMessageBtn: false,
  topMessageId: null,
  isInitialPageLoaded: false,
  activeMessageId: null,
};

interface ChatRoomStoreMap {
  [key: string]: UseBoundStore<StoreApi<ChatRoomStore>>;
}

interface ChatStoreProps {
  chatRoomsMap: ChatRoomStoreMap;
  getChatRoomStore: (uuid: string) => UseBoundStore<StoreApi<ChatRoomStore>>;
  unreadChatRoomUuids: {
    [key: number]: string[];
  };
  setUnreadChatRoomUuids: (communityId: number, uuids: string[]) => void;
  appendUnreadChatRoomUuids: (communityId: number, uuid: string) => void;
}

const createChatRoomStore = () =>
  create(
    immer<ChatRoomStore>(set => ({
      ...initialChatRoomState,
      setChatRoomMessages: messages =>
        set(state => {
          state.chatRoomMessages = messages;
        }),
      setIsLoading: isLoading =>
        set(state => {
          state.isLoading = isLoading;
        }),
      setIsInitialPageLoaded: isLoaded =>
        set(state => {
          state.isInitialPageLoaded = isLoaded;
        }),
      setHasNextPage: hasNextPage =>
        set(state => {
          state.hasNextPage = hasNextPage;
        }),
      setHasPrevPage: hasPrevPage =>
        set(state => {
          state.hasPrevPage = hasPrevPage;
        }),
      setScrollInfo: scrollInfo =>
        set(state => {
          state.scrollInfo = scrollInfo;
        }),
      setFirstMessageId: id =>
        set(state => {
          state.firstMessageId = id;
        }),
      setLastMessageId: id =>
        set(state => {
          state.lastMessageId = id;
        }),
      setShouldShowLatestMessageBtn: shouldShowLatestMessageBtn =>
        set(state => {
          state.shouldShowLatestMessageBtn = shouldShowLatestMessageBtn;
        }),
      setTopMessageId: id =>
        set(state => {
          state.topMessageId = id;
        }),
      setActiveMessageId: id =>
        set(state => {
          state.activeMessageId = id;
        }),
      resetChatRoom: () => set(() => ({ ...initialChatRoomState })),
    })),
  );

export const useChatStore = create(
  immer<ChatStoreProps>((set, get) => ({
    chatRoomsMap: {},
    getChatRoomStore: (uuid: string) => {
      let chatRoom = get().chatRoomsMap[uuid];
      if (!chatRoom) {
        chatRoom = createChatRoomStore();
        set(state => {
          state.chatRoomsMap[uuid] = chatRoom;
        });
      }
      return chatRoom;
    },
    unreadChatRoomUuids: {},
    setUnreadChatRoomUuids: (communityId, uuids) =>
      set(state => {
        state.unreadChatRoomUuids = {
          ...state.unreadChatRoomUuids,
          [communityId]: uuids,
        };
      }),
    appendUnreadChatRoomUuids: (communityId, uuid) =>
      set(state => {
        state.unreadChatRoomUuids = {
          ...state.unreadChatRoomUuids,
          [communityId]: [
            ...(state.unreadChatRoomUuids[communityId] || []),
            uuid,
          ],
        };
      }),
  })),
);

export const useChatRoomStore = () => {
  const { getChatRoomStore } = useChatStore();
  const { chatRoomUuid } = useChatRoomContext();
  return getChatRoomStore(chatRoomUuid)();
};
