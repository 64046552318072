import type { MouseEvent } from "react";
import classNames from "classnames";
import { t } from "i18n-js";
import { ProgressiveImage } from "@circle-react/components/shared/ProgressiveImage";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import type { Post } from "@circle-react/types";
import { ShowPageLink } from "./ShowPageLink";

export interface PostCoverImageProps {
  post: Post;
  className?: string;
  wrapperClassName?: string;
  startsAt?: string;
  endsAt?: string;
  timeZone?: string;
  onClick?: (event: MouseEvent<HTMLAnchorElement>) => void;
}

export const PostCoverImage = ({
  post,
  className = "",
  wrapperClassName = "",
  ...rest
}: PostCoverImageProps) => {
  const { isV3Enabled } = useIsV3();

  const coverImageUrl =
    post.cover_image_variants?.large ?? post.cover_image_url;

  if (!coverImageUrl) {
    return null;
  }

  return (
    <div className={classNames("px-5 pt-5 md:p-0", wrapperClassName)}>
      <ShowPageLink
        shouldOpenPostModal
        post={post}
        className="post__cover focus-visible:outline-secondary focus-visible:text-dark block focus-visible:rounded-lg focus-visible:outline focus-visible:outline-2 focus-visible:md:rounded-b-none"
        {...rest}
      >
        {post.cover_image_variants && (
          <ProgressiveImage
            key={post.cover_image_url ?? ""}
            src={post.cover_image_url ?? ""}
            srcVariants={post.cover_image_variants}
            alt={t("post.cover_image_alt")}
            className={classNames(
              "w-full object-cover md:rounded-b-none",
              className,
              {
                "rounded-lg": !isV3Enabled,
                "rounded-2xl": isV3Enabled,
              },
            )}
          />
        )}
      </ShowPageLink>
    </div>
  );
};
