import { t } from "i18n-js";
import { useForm } from "react-hook-form";
import { useCurrentPostContext } from "@circle-react/contexts";
import type { Post } from "@circle-react/types";
import { Form } from "@circle-react-shared/uikit/Form/Form";
import { ImageInput } from "@circle-react-shared/uikit/ImageInput";
import { useUpdatePostThumbnailMutation } from "./useUpdatePostThumbnailMutation";

export const CardViewThumbnailModal = ({
  post,
  isOpen,
  onClose,
  onChange,
}: {
  post: Post;
  isOpen: boolean;
  onClose: () => void;
  onChange: (value: Post, options: any) => void;
}) => {
  const { setIsUpdatingCardViewThumbnail } = useCurrentPostContext();
  const updatePostThumbnail = useUpdatePostThumbnailMutation({
    post,
    onSuccess: responseData => {
      onChange(responseData, { refetch: false });
      setIsUpdatingCardViewThumbnail?.(false);
    },
    onError: () => {
      setIsUpdatingCardViewThumbnail?.(false);
    },
  });

  const handleSubmit = (formData: any) => {
    updatePostThumbnail.mutate(formData);
  };

  const form = useForm({
    defaultValues: {
      cardview_thumbnail: post.cardview_thumbnail,
    },
  });

  return (
    <Form {...form} onSubmit={handleSubmit}>
      {({ triggerFormSubmit }) => (
        <ImageInput
          isOpen={isOpen}
          name="cardview_thumbnail"
          onClose={() => {
            onClose();
          }}
          aspectRatio={600 / 300}
          title={t("upload_a_cardview_thumbnail")}
          renderer={() => <>{}</>}
          onImageAttachSuccess={triggerFormSubmit}
          onProcessing={processing =>
            processing && setIsUpdatingCardViewThumbnail?.(processing)
          }
        />
      )}
    </Form>
  );
};
