import { useMemo, useRef } from "react";
import classnames from "classnames";
import { t } from "i18n-js";
import type { Space } from "@/react/types/Space";
import { ShowPageLink } from "@circle-react/components/PostsV3/PostViewListItem/ShowPageLink";
import { useCurrentPostHelpers } from "@circle-react/components/PostsV3/useCurrentPostHelpers";
import { useCurrentPostContext } from "@circle-react/contexts";
import { isIntendedOnNewTab } from "@circle-react/helpers/browserHelpers";
import {
  dateStringToTimeAgo,
  dateToTimeAgoFormats,
} from "@circle-react/helpers/dateTimeHelpers/timeAgo";
import {
  getDetailedTimestamp,
  isImagePost,
} from "@circle-react/helpers/postHelpers";
import { useSmScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { TippyV2 } from "@circle-react-shared/TippyV2";
import { MemberTags } from "@circle-react-uikit/MemberTags";
import { AuthorHeadline } from "./AuthorHeadline";
import { AuthorProfileWrapper } from "./AuthorProfileWrapper";
import { PostUserInfo } from "./PostUserInfo";
import { PostViewListItemWrapper } from "./PostViewListItemWrapper";

export interface PostMetaInfoProps {
  doesPostHaveCoverImage?: boolean;
  hideAuthorHeadline?: boolean;
  isRenderedOutsideTheSpace?: boolean;
  shouldRenderMetaFirstAndThenTitle?: boolean;
  space?: Space;
  tagsLimit?: number;
  tagsShowMoreLabelVariant?: "words" | "numbers";
  wrapperClassName?: string;
}

export const PostMetaInfo = ({
  doesPostHaveCoverImage = false,
  hideAuthorHeadline,
  isRenderedOutsideTheSpace,
  space,
  tagsLimit,
  tagsShowMoreLabelVariant = "numbers",
  wrapperClassName,
}: PostMetaInfoProps) => {
  const { post, isSharedPostView } = useCurrentPostHelpers();
  const { usedIn } = useCurrentPostContext();
  const shouldHideMetaInfo = Boolean(post?.hide_meta_info);
  const communityMember = post?.community_member;
  const memberTags: any[] = post?.member_tags || [];
  const memberId = communityMember?.id;

  const visibleMemberTags = memberTags?.filter((memberTag: any) =>
    Boolean(memberTag?.is_public && memberTag?.display_locations?.post_bio),
  );

  const hasTags = useMemo(
    () => Boolean(memberId && visibleMemberTags?.length > 0),
    [memberId, visibleMemberTags],
  );

  const isSmScreen = useSmScreenMediaQuery();
  const containerRef = useRef<HTMLDivElement>(null);
  const pageLinkRef = useRef<HTMLAnchorElement>(null);

  if (shouldHideMetaInfo || !space) {
    return null;
  }

  const headline = isRenderedOutsideTheSpace
    ? `${t("post.posted_in")} ${space.name}`
    : communityMember?.headline;

  const isAdmin = Boolean(communityMember?.admin);
  const shouldDisplayMemberTags = !isSharedPostView && (hasTags || isAdmin);

  if (isSmScreen || usedIn === "full-post-modal") {
    return (
      <PostViewListItemWrapper
        doesPostHaveCoverImage={doesPostHaveCoverImage}
        ref={containerRef}
        className={wrapperClassName}
      >
        <PostUserInfo post={post} />
        <button
          type="button"
          className="post__bio focus-visible:outline-secondary flex flex-col justify-between gap-1 self-center text-left focus-visible:rounded-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
          onClick={event => {
            if (!(event.target instanceof HTMLAnchorElement)) {
              pageLinkRef.current && pageLinkRef.current.click();
            }
          }}
        >
          <div
            className={classnames(
              "author flex flex-wrap items-center gap-x-2 gap-y-1",
              {
                "no-headline": !communityMember?.headline && post.name,
                "has-tags": hasTags,
              },
            )}
          >
            <div className="author__name inline-flex">
              <AuthorProfileWrapper
                post={post}
                className="!text-dark text-sm font-semibold leading-5"
              >
                {communityMember?.name}
              </AuthorProfileWrapper>
            </div>
            {shouldDisplayMemberTags && (
              <div className="author__tags member-tags !m-0">
                <MemberTags
                  memberTags={visibleMemberTags}
                  isAdmin={isAdmin}
                  limit={tagsLimit}
                  showMoreLabelVariant={tagsShowMoreLabelVariant}
                />
              </div>
            )}
          </div>
          {!hideAuthorHeadline && (
            <AuthorHeadline headline={headline} post={post} ref={pageLinkRef} />
          )}
        </button>
      </PostViewListItemWrapper>
    );
  }

  return (
    <PostViewListItemWrapper
      doesPostHaveCoverImage={doesPostHaveCoverImage}
      ref={containerRef}
      className={wrapperClassName}
    >
      <PostUserInfo post={post} />
      <button
        type="button"
        className="post__bio focus-visible:outline-secondary flex flex-col justify-between self-center text-left focus-visible:rounded-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
        onClick={event => {
          if (
            !(event.target instanceof HTMLAnchorElement) &&
            pageLinkRef.current
          ) {
            if (isIntendedOnNewTab(event)) {
              const url = pageLinkRef.current.href;
              window.open(url, "_blank", "noreferrer");
              event.preventDefault();
            } else {
              pageLinkRef.current.click();
            }
          }
        }}
      >
        <div
          className={classnames(
            "author flex flex-wrap items-center gap-x-2 gap-y-px",
            {
              "no-headline": !communityMember?.headline && post.name,
              "has-tags": hasTags,
            },
          )}
        >
          <div className="author__name inline-flex">
            <AuthorProfileWrapper
              post={post}
              className="!text-dark overflow-anywhere text-sm font-semibold leading-5"
            >
              {communityMember?.name}
            </AuthorProfileWrapper>
          </div>
          {shouldDisplayMemberTags && (
            <div className="author__tags member-tags">
              <MemberTags
                memberTags={visibleMemberTags}
                isAdmin={isAdmin}
                limit={isSmScreen || isImagePost(post) ? 1 : 2}
                showMoreLabelVariant={tagsShowMoreLabelVariant}
              />
            </div>
          )}
          <div className="author__time inline-flex">
            <ShowPageLink
              post={post}
              className="ago text-default hover:text-dark focus-visible:outline-secondary focus-visible:text-dark text-xs focus-visible:rounded-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
              forwardedRef={pageLinkRef}
            >
              <TippyV2
                interactive={false}
                content={getDetailedTimestamp(post.created_at)}
              >
                {dateStringToTimeAgo(post.created_at, {
                  format: dateToTimeAgoFormats.short,
                })}
              </TippyV2>
            </ShowPageLink>
          </div>
        </div>
        {!hideAuthorHeadline && (
          <AuthorHeadline headline={headline} post={post} />
        )}
      </button>
    </PostViewListItemWrapper>
  );
};
