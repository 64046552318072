import PropTypes from "prop-types";
import classnames from "classnames";
import { Rsvp } from "@circle-react/components/Events/Rsvp";
import "./styles.scss";

export const Sidebar = ({ onChange, insidePost = false }) => (
  <section
    className={classnames("right-sidebar-container", {
      "right-sidebar-container--insidePost": insidePost,
    })}
  >
    <Rsvp onChange={onChange} />
  </section>
);

// TODO: Properly define prop types
Sidebar.propTypes = {
  event: PropTypes.any,
};
