import { useCallback, useEffect, useRef, useState } from "react";
import type { RefObject } from "react";
import type { Editor } from "@tiptap/react";
import { defer } from "lodash";
import { useFormContext } from "react-hook-form";
import type { UseFormReturn } from "react-hook-form";
import { useHotkeys } from "react-hotkeys-hook";
import { isValidTiptapBody } from "@circle-react/components/QuickPostV2/validation";
import {
  mentionBlock,
  textBlock,
} from "@circle-react-shared/uikit/TipTap/utilities/contentBuilder";

interface UseEditorInteractionsProps {
  editorRef?: RefObject<Editor>;
  autoFocus?: boolean;
  communityMemberForReply: any;
  currentCommunityMember: any;
  initialSgidToObjectMap: object;
  parentCommentId?: number;
  usedIn: any;
  toolbarPortalId: string;
}

const hasTriggerFormSubmit = (
  form: UseFormReturn<any>,
): form is UseFormReturn<any> & { triggerFormSubmit: () => void } =>
  "triggerFormSubmit" in form;

export const useEditorInteractions = ({
  editorRef,
  autoFocus,
  parentCommentId,
  communityMemberForReply,
  currentCommunityMember,
  initialSgidToObjectMap,
  usedIn,
  toolbarPortalId,
}: UseEditorInteractionsProps) => {
  const [isEditorExpanded, setIsEditorExpanded] = useState(false);
  const [editorKey, setEditorKey] = useState(0);
  const [sgidToObjectMap, setSgidToObjectMap] = useState(
    initialSgidToObjectMap,
  );
  const commentBoxRef = useRef<HTMLDivElement>(null);
  const toolbarRef = useRef<HTMLElement | null>(null);

  const formContext = useFormContext();
  const {
    watch,
    reset,
    formState: { isSubmitting, isSubmitSuccessful },
  } = formContext;

  const tiptapBody = watch("tiptap_body");
  const isEditorEmpty = isValidTiptapBody(tiptapBody);
  const isSubmitDisabled = !isEditorEmpty || isSubmitting;
  const isSubmitShortcutEnabled = isEditorExpanded && !isSubmitDisabled;
  const isUsedInMinimalPostModal = usedIn === "minimal-post-modal";
  const isReply = Boolean(parentCommentId);
  const isPostCommentFormInMinimalPostModal =
    isUsedInMinimalPostModal && !isReply;

  useEffect(() => {
    defer(() => {
      if (autoFocus && !isPostCommentFormInMinimalPostModal) {
        editorRef?.current?.commands?.focus?.("end");
      }
    });
  }, [autoFocus, isPostCommentFormInMinimalPostModal, editorRef]);

  useEffect(() => {
    toolbarRef.current = document.getElementById(toolbarPortalId);
  }, [toolbarPortalId]);

  useEffect(() => {
    if (!isUsedInMinimalPostModal) return;

    const handleFocusChange = () => {
      /*
       * We need to defer the check to ensure that the activeElement is updated.
       * We check if the active element is a direct child of the comment box to reply to a post and not a comment.
       */
      setTimeout(() => {
        const activeElement = document.activeElement;
        const isToolbarFocused = toolbarRef.current?.contains(activeElement);
        const postReplyElement = document.querySelector(
          ".post__reply:not(.reply-to-parent)",
        );
        const isChildOfPostReply = postReplyElement?.contains(activeElement);

        if (isToolbarFocused || isChildOfPostReply) {
          setIsEditorExpanded(true);
        } else {
          setIsEditorExpanded(false);
        }
      }, 0);
    };

    document.addEventListener("focusin", handleFocusChange);
    document.addEventListener("focusout", handleFocusChange);

    return () => {
      document.removeEventListener("focusin", handleFocusChange);
      document.removeEventListener("focusout", handleFocusChange);
    };
  }, [isUsedInMinimalPostModal]);

  const handleEditorFocus = useCallback(() => {
    if (isUsedInMinimalPostModal) {
      setIsEditorExpanded(true);
    }
  }, [isUsedInMinimalPostModal]);

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset();
      setEditorKey(key => key + 1);
    }
  }, [isSubmitSuccessful, reset]);

  useEffect(() => {
    if (
      communityMemberForReply &&
      communityMemberForReply.id !== currentCommunityMember?.id
    ) {
      setSgidToObjectMap(prevMap => ({
        ...prevMap,
        [communityMemberForReply.rich_text_field_sgid]: communityMemberForReply,
      }));
      defer(() => {
        editorRef?.current
          ?.chain?.()
          .focus("start")
          .insertContent([
            mentionBlock({
              sgid: communityMemberForReply.rich_text_field_sgid,
            }),
            textBlock({
              text: " ",
            }),
          ])
          .run();
      });
    }
  }, [communityMemberForReply, currentCommunityMember, editorRef]);

  useHotkeys(
    "cmd+enter, ctrl+enter",
    () => {
      if (hasTriggerFormSubmit(formContext)) {
        formContext.triggerFormSubmit();
      }
    },
    {
      enableOnContentEditable: true,
      enabled: isSubmitShortcutEnabled,
    },
    [formContext, isSubmitShortcutEnabled],
  );

  return {
    isEditorExpanded,
    commentBoxRef,
    handleEditorFocus,
    editorKey,
    sgidToObjectMap,
    isSubmitDisabled,
    isSubmitting,
    isUsedInMinimalPostModal,
    isPostCommentFormInMinimalPostModal,
  };
};
