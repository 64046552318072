import { useState } from "react";
import { format } from "date-fns";
import { t } from "i18n-js";
import { DatePicker } from "@circle-react/components/QuickPost/ModalForm/SubmitButton/DatePicker";
import { SCHEDULED_POST_DATE_RENDER_PORTAL_ID } from "@circle-react/components/QuickPostV2/Content";
import { Icon } from "@circle-react/components/shared/Icon";
import { IconButton } from "@circle-react/components/shared/uikit/HeaderV3/IconButton";
import { Portal } from "@circle-react-uikit/Portal";
import { Typography } from "@circle-react-uikit/Typography";

interface ScheduledBarProps {
  publishedAt: string;
  updatePublishedAt: (date: string) => void;
  status: "draft" | "scheduled" | "published";
  revertToDraftState: () => void;
  variant?: "default" | "2-lines";
}

export const ScheduledBar = ({
  publishedAt,
  updatePublishedAt,
  status,
  revertToDraftState,
  variant = "default",
}: ScheduledBarProps) => {
  const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);

  const closeDatePicker = () => setIsDatePickerVisible(false);
  const dateFormat = "MMM dd, yyyy 'at' HH:mm";

  const onScheduleDateChange = (date: any) => {
    updatePublishedAt(date);
    closeDatePicker();
  };

  if (status !== "scheduled") {
    return null;
  }
  return (
    <Portal selectorQuery={`#${SCHEDULED_POST_DATE_RENDER_PORTAL_ID}`}>
      <DatePicker
        value={publishedAt}
        datePickerVisible={isDatePickerVisible}
        onCancel={closeDatePicker}
        onSave={onScheduleDateChange}
        placement="bottom-end"
        saveButtonVariant="circle"
      >
        <div className="border-primary bg-secondary -mt-px flex flex-col border-y px-5 py-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-2">
              <Icon type="16-clock" size={16} />
              <Typography.LabelSm
                weight={variant === "2-lines" ? "semibold" : "medium"}
              >
                {variant === "default"
                  ? t("quick_post_v2.scheduled_for", {
                      date: format(new Date(publishedAt), dateFormat),
                    })
                  : t("quick_post_v2.post_scheduled")}
              </Typography.LabelSm>
            </div>
            <div className="flex items-center space-x-3">
              <IconButton
                variant="secondary"
                name="16-edit"
                iconClassName="!text-dark"
                onClick={() => setIsDatePickerVisible(true)}
                ariaLabel={t("edit")}
              />
              <IconButton
                variant="secondary"
                name="16-trash-bin"
                iconClassName="!text-dark"
                onClick={revertToDraftState}
                ariaLabel={t("delete")}
              />
            </div>
          </div>
          {variant === "2-lines" && (
            <div className="mt-1">
              <Typography.LabelSm weight="normal">
                {format(new Date(publishedAt), dateFormat)}
              </Typography.LabelSm>
            </div>
          )}
        </div>
      </DatePicker>
    </Portal>
  );
};
