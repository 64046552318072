import { t } from "i18n-js";
import { Profile } from "@/react/components/Profile/Profile";
import { LoaderRow } from "@circle-react-shared/LoaderRow";
import { Typography } from "@circle-react-uikit/Typography";
import { MobileProfileDrawerHeader } from "./MobileProfileDrawerHeader";

interface ProfileBodyProps {
  isLoading: boolean;
  isError: boolean;
  isSmScreen: boolean;
  member: any;
  drawer: any;
  memberPublicId: string;
  shouldOpenInNewTab?: boolean;
  onOpenMessaging: (chatRoomUuid: string) => void;
}

export const ProfileBody = ({
  isLoading,
  isError,
  isSmScreen,
  member,
  drawer,
  memberPublicId,
  shouldOpenInNewTab,
  onOpenMessaging,
}: ProfileBodyProps) => {
  if (isLoading) {
    return <LoaderRow />;
  }

  if (isError) {
    return (
      <p className="p-6">
        <Typography.BodyMd>{t("error_loading_profile")}</Typography.BodyMd>
      </p>
    );
  }

  return (
    <>
      {isSmScreen && (
        <MobileProfileDrawerHeader
          memberPublicId={memberPublicId}
          member={member}
          drawer={drawer}
        />
      )}
      <Profile
        memberPublicId={memberPublicId}
        member={member}
        shouldOpenInNewTab={shouldOpenInNewTab}
        onOpenMessaging={onOpenMessaging}
        onClose={drawer.hide}
        isFullPageProfile={false}
      />
    </>
  );
};
