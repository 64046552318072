import { useLayoutEffect, useRef } from "react";

export const useScrollIntoView = shouldScrollIntoView => {
  const elementRef = useRef(null);
  useLayoutEffect(() => {
    if (shouldScrollIntoView) {
      elementRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [shouldScrollIntoView]);

  return elementRef;
};
