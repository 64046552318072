import { useEffect } from "react";
import InnerHTML from "dangerously-set-html-content";
import { t } from "i18n-js";
import { isFunction } from "lodash";
import { useBackendProps } from "@circle-react/providers";
import { SeeMoreLess } from "@circle-react-shared/uikit/SeeMoreLess";
import { TipTapBlockEditor } from "@circle-react-uikit/TipTapBlockEditor";
import { useCurrentPostHelpers } from "../useCurrentPostHelpers";
import { useMinimalTipTapContent } from "./useMinimalTipTapContent";

export const MinimalTiptapContent = () => {
  const { minimal_layout: isMinimalLayout } = useBackendProps();
  const {
    mediaContent,
    otherContent,
    isMediaContentEmpty,
    isOtherContentEmpty,
    tiptapBody,
    redirectToPostModal,
  } = useMinimalTipTapContent();
  const { post } = useCurrentPostHelpers();

  const { internal_custom_html: html } = post;

  useEffect(() => {
    if (html && isFunction(window?.loadOembed)) {
      window.loadOembed();
    }
  }, [html]);

  return (
    <div className="flex w-full flex-col gap-2">
      <SeeMoreLess hideToggle lines={8}>
        {otherContent && !isOtherContentEmpty && (
          <TipTapBlockEditor
            readOnly
            disableMentionClicks={isMinimalLayout}
            value={otherContent}
            shouldRefetch
            sgidToObjectMap={tiptapBody.sgids_to_object_map}
            inlineAttachments={tiptapBody.inline_attachments}
            disabledExtensions={["cta"]}
          />
        )}
        {html && <InnerHTML key="internal_custom_html" html={html} />}
      </SeeMoreLess>
      {otherContent && !isOtherContentEmpty && (
        <button
          type="button"
          className="text-light hover:text-dark focus-visible:text-dark inline-flex text-base"
          data-testid="see-more-post"
          onClick={redirectToPostModal}
        >
          {t("see_more")}
        </button>
      )}

      {mediaContent && !isMediaContentEmpty && (
        <TipTapBlockEditor
          readOnly
          disableMentionClicks={isMinimalLayout}
          sgidToObjectMap={tiptapBody.sgids_to_object_map}
          inlineAttachments={tiptapBody.inline_attachments}
          value={mediaContent}
          shouldRefetch
          className="w-full"
        />
      )}
    </div>
  );
};
