export const paywallCurrencyDirections = {
  ltr: "ltr",
  rtl: "rtl",
} as const;
export type PaywallCurrencyDirection = keyof typeof paywallCurrencyDirections;
export const paywallCurrencySmallestUnits = {
  decimal: "decimal",
  zero_decimal: "zero_decimal",
} as const;
export type PaywallCurrencySmallestUnit =
  keyof typeof paywallCurrencySmallestUnits;
export interface PaywallCurrency {
  id: number;
  code: string;
  symbol: string;
  direction: PaywallCurrencyDirection;
  smallest_unit: PaywallCurrencySmallestUnit;
  min_charge_amount: number;
  max_charge_amount: number;
  is_default: boolean;
}

export const paywallTypes = {
  space: "space",
  event: "event",
} as const;
export type PaywallType = keyof typeof paywallTypes;

export const paywallPriceTypes = {
  onetime: "onetime",
  subscription: "subscription",
  installments: "installments",
} as const;
export type PaywallPriceType = keyof typeof paywallPriceTypes;

export const paywallPriceIntervals = {
  daily: "daily",
  weekly: "weekly",
  monthly: "monthly",
  yearly: "yearly",
} as const;
export type PaywallPriceInterval = keyof typeof paywallPriceIntervals;

export const paywallPriceFrequencies = {
  daily: "daily",
  weekly: "weekly",
  biweekly: "biweekly",
  monthly: "monthly",
  quarterly: "quarterly",
  biannual: "biannual",
  annual: "annual",
} as const;
export type PaywallPriceFrequency = keyof typeof paywallPriceFrequencies;

export interface PaywallPriceBuildOptionsFrequency {
  interval: PaywallPriceInterval;
  interval_count: number;
  as_adverb: string;
  as_adjective: string;
  as_phrase: string;
}

export interface PaywallPriceBuildOptionsEntry {
  name: string;
  frequencies: {
    [frequency: string]: PaywallPriceBuildOptionsFrequency;
  };
  limits: {
    [limit: string]: {
      min: number;
      max: number;
    };
  };
}

export interface PaywallPriceBuildOptions {
  [type: string]: PaywallPriceBuildOptionsEntry;
}

interface BasePaywallPrice {
  id: number;
  price_type: PaywallPriceType;
  amount: number;
  currency_id: number;
  preferred: boolean;
  display_name?: string;
  interval?: PaywallPriceInterval;
  interval_count?: number;
  installments_count?: number;
  upfront_payment_amount?: number;
  trial_requires_upfront_payment: boolean;
}
interface BaseRecurringPaywallPrice extends BasePaywallPrice {
  interval: PaywallPriceInterval;
  interval_count: number;
}
export interface OnetimePaywallPrice extends BasePaywallPrice {
  price_type: "onetime";
}
export interface SubscriptionPaywallPrice extends BaseRecurringPaywallPrice {
  price_type: "subscription";
}
export interface InstallmentsPaywallPrice extends BaseRecurringPaywallPrice {
  price_type: "installments";
  installments_count: number;
}
export type PaywallPrice =
  | OnetimePaywallPrice
  | SubscriptionPaywallPrice
  | InstallmentsPaywallPrice;

export interface Paywall {
  id: number;
  display_name: string;
  prices: Array<PaywallPrice>;
}

export interface PaywallCheckoutPreview {
  community_id: number;
  community_member_id?: number;
  community_member_subscription_id?: number;
  paywall_id: number;
  paywall_price_id: number;
  paywall_coupon_id?: number;
  currency: PaywallCurrency;
  amount_due_now: number;
  formatted_amount_due_now: string;
  trial_period?: {
    days: number;
    free: boolean;
    paid: boolean;
    ends_on?: string;
    formatted_ends_on?: string;
  };
  first_payment: {
    price_amount: number;
    formatted_price_amount: string;
    prorated_credit_description: string;
    prorated_credit_amount: number;
    formatted_prorated_credit_amount: string;
    subtotal: number;
    formatted_subtotal: string;
    discount: number;
    formatted_discount: string;
    tax: number;
    formatted_tax: string;
    total: number;
    formatted_total: string;
    starting_balance: number;
    formatted_starting_balance: string;
    amount_due: number;
    formatted_amount_due: string;
    carry_over_amount: number;
    formatted_carry_over_amount: string;
    ending_balance: number;
    formatted_ending_balance: string;
    due_on: number;
    formatted_due_on: string;
    due_on_phrase: string;
  };
  future_payments?: {
    subtotal: number;
    formatted_subtotal: string;
    discount_phrase: string;
    discount: number;
    formatted_discount: string;
    tax: number;
    formatted_tax: string;
    total: number;
    formatted_total: string;
    starting_balance: number;
    formatted_starting_balance: string;
    amount_due: number;
    formatted_amount_due: string;
    carry_over_amount: number;
    formatted_carry_over_amount: string;
    ending_balance: number;
    formatted_ending_balance: string;
    start_on: number;
    formatted_start_on: string;
    start_on_phrase: string;
  };
}
