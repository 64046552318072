import { t } from "i18n-js";
import { useMutation } from "react-query";
import { useHistory, useRouteMatch } from "react-router-dom";
import { isBasicPost } from "@/react/helpers/postHelpers";
import { isCardViewSpace, isListViewSpace } from "@/react/helpers/spaceHelpers";
import { canManageSpace } from "@/react/helpers/spaceHelpers";
import { postApi } from "@circle-react/api";
import { useCurrentPostHelpers } from "@circle-react/components/PostsV3/useCurrentPostHelpers";
import {
  useCurrentPostContext,
  useCurrentSpaceContext,
} from "@circle-react/contexts";
import { afterSaveRedirectPath } from "@circle-react/helpers/postHelpers";
import { useSmScreenMediaQuery } from "@circle-react/hooks/useMediaQuery";
import { useBoolean } from "@circle-react/hooks/utils/useBoolean";
import type { Post } from "@circle-react/types";
import { useToast } from "@circle-react-uikit/ToastV2";

export const usePostDropdownActions = ({
  onPostDestroy,
}: {
  onPostDestroy: (data: any) => void;
}) => {
  const { data: space, refetch } = useCurrentSpaceContext();
  const { post, showShareOnWebOptions } = useCurrentPostHelpers();
  const { usedIn } = useCurrentPostContext();
  const history = useHistory();

  const [shouldShowEditPostModal, toggleEditPostModal] = useBoolean(false);
  const [shouldShowDuplicatePostModal, toggleDuplicatePostModal] =
    useBoolean(false);
  const [shouldShowReportPostModal, toggleReportPostModal] = useBoolean(false);
  const [shouldShowConfirmationModal, toggleConfirmationModal] =
    useBoolean(false);
  const [shouldShowSetThumbnailModal, toggleSetThumbnailModal] =
    useBoolean(false);

  const isPostBasicPost = isBasicPost(post);
  const isSmScreen = useSmScreenMediaQuery();
  const { success, error } = useToast();

  const shouldShowPinToSidebar =
    isPostBasicPost &&
    Boolean(!space?.hide_right_sidebar) &&
    !isCardViewSpace(space);

  const isPostCardOrListView = isCardViewSpace(space) || isListViewSpace(space);
  const isPostDetailsPage = Boolean(useRouteMatch("/c/:spaceSlug/:postSlug"));
  const isCurrentPostPinnedAtTopOfSpace = post.pinned_at_top_of_space;
  const isEligibleForSharing =
    !isPostDetailsPage &&
    !isCurrentPostPinnedAtTopOfSpace &&
    isPostCardOrListView;
  const isRenderedOnSmallScreensOrFullPostModal =
    isSmScreen || usedIn === "full-post-modal";
  const shouldShowShareOnWebOptions = isRenderedOnSmallScreensOrFullPostModal
    ? showShareOnWebOptions(space)
    : isEligibleForSharing && showShareOnWebOptions(space);
  const shouldShowSetThumbnailOptionForPosts =
    isCardViewSpace(space) &&
    !isCurrentPostPinnedAtTopOfSpace &&
    canManageSpace(space);

  const deleteMutation = useMutation(() => postApi.destroy({ post }), {
    onSuccess: () => {
      onPostDestroy(post.id);
      success(t("post_dropdown_actions.delete_successful"));
      void refetch();
    },
    onError: () => error(t("post_dropdown_actions.delete_failed")),
  });

  const toggleEditorTypeMutation = useMutation(
    () => postApi.toggleEditorType({ post }),
    {
      onSuccess: () => {
        success(t("post_dropdown_actions.toggle_editor_successful"));
      },
      onError: () => error(t("post_dropdown_actions.toggle_editor_failed")),
    },
  );

  const redirectToPost = (post: Post) =>
    history.push(afterSaveRedirectPath({ post }));

  return {
    shouldShowPinToSidebar,
    isPostCardOrListView,
    isPostDetailsPage,
    isCurrentPostPinnedAtTopOfSpace,
    isEligibleForSharing,
    isRenderedOnSmallScreensOrFullPostModal,
    shouldShowShareOnWebOptions,
    shouldShowSetThumbnailOptionForPosts,
    shouldShowEditPostModal,
    toggleEditPostModal,
    shouldShowDuplicatePostModal,
    toggleDuplicatePostModal,
    shouldShowReportPostModal,
    toggleReportPostModal,
    shouldShowConfirmationModal,
    toggleConfirmationModal,
    deleteMutation,
    toggleEditorTypeMutation,
    redirectToPost,
    shouldShowSetThumbnailModal,
    toggleSetThumbnailModal,
  };
};
