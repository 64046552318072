import PropTypes from "prop-types";
import { t } from "i18n-js";
import { isEmpty } from "lodash";
import { useHistory } from "react-router-dom";
import {
  ChatThreadsContextProvider,
  useUnreadChatThreads,
} from "@/react/hooks/chatsV2";
import { markChatThreadsAsRead } from "@circle-react/api";
import { RailBarPortal } from "@circle-react/components/Layout/StandardLayoutV2/RailBarPortal";
import { removeQueryParam } from "@circle-react/helpers/urlHelpers/toQueryString";
import { useRailbar } from "@circle-react/hooks/chatsV2/useRailbar";
import { useElementHeight } from "@circle-react/hooks/utils/useElementHeight";
import { Rail } from "@circle-react-uikit/Rail";
import { Content } from "./Content";
import { Header } from "./Header";

export const ChatThreads = () => {
  const { ref: headerRef, height: headerHeight } = useElementHeight();
  const history = useHistory();
  const {
    closeThreadsRailbar,
    parentMessage,
    chatRoom = {},
    messageHighlightId,
    setMessageHighlightId,
    shouldShowRailBar,
    isCourseSpace,
    isDrawer,
  } = useRailbar();

  const { unreadChatThreadIds, isUnreadThreadFlow } = useUnreadChatThreads();

  const onClose = () => {
    if (messageHighlightId) {
      history.push({
        search: removeQueryParam("message_id"),
      });
      setMessageHighlightId(null);
    }
    if (
      parentMessage.chat_thread_id &&
      unreadChatThreadIds.includes(parentMessage.chat_thread_id) &&
      !isUnreadThreadFlow
    ) {
      markChatThreadsAsRead(parentMessage.chat_thread_id);
    }
    closeThreadsRailbar();
  };

  if (!shouldShowRailBar || isEmpty(chatRoom)) {
    return null;
  }

  const position = isCourseSpace ? "portal" : isDrawer ? "drawer" : "header";
  return (
    <RailBarPortal position={position} noBorderLeft={isCourseSpace}>
      <div ref={headerRef}>
        <Rail.Header>
          <Header title={t("messaging.thread")} onClose={onClose} />
        </Rail.Header>
      </div>
      <Rail.Content
        heightGap={headerHeight}
        isCourseSpace={isCourseSpace}
        noPadding
      >
        <ChatThreadsContextProvider>
          <Content parentMessage={parentMessage} chatRoom={chatRoom} />
        </ChatThreadsContextProvider>
      </Rail.Content>
    </RailBarPortal>
  );
};

ChatThreads.propTypes = {
  chatRoomUuid: PropTypes.string,
};
