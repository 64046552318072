import { useHistory, useLocation } from "react-router-dom";
import { adminMemberEditPathsWithState } from "@circle-react/helpers/urlHelpers";

export const useAdminMemberEditModal = () => {
  const location = useLocation();
  const history = useHistory();

  return publicUid => {
    history.push(adminMemberEditPathsWithState.index(publicUid, location));
  };
};
