import { useState } from "react";
import classnames from "classnames";
import { t } from "i18n-js";
import { useCurrentPostHelpers } from "@circle-react/components/PostsV3/useCurrentPostHelpers";
import { useIsOrCanBeCommunityMember } from "@circle-react/contexts";
import { useIsV3 } from "@circle-react/hooks/useIsV3";
import { Icon } from "@circle-react-shared/Icon";

export const LikeButton = () => {
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const { isV3Enabled } = useIsV3();
  const isOrCanBeCommunityMember = useIsOrCanBeCommunityMember();
  const { post, ...postHelpers } = useCurrentPostHelpers();

  const {
    is_liking_enabled: isLikingEnabled,
    is_liked_by_current_user: isLikedByCurrentUser,
    post_details_loaded,
  } = post;

  if (!isLikingEnabled || !isOrCanBeCommunityMember) return null;

  return (
    <button
      type="button"
      aria-label={t("like_post_aria_label", {
        name: post?.name ?? "",
      })}
      onClick={event => {
        setShouldAnimate(true);
        setTimeout(() => {
          setShouldAnimate(false);
        }, 200);
        void postHelpers.onLikeToggle?.(event);
      }}
      className={classnames(
        "action-link post__actions--like hover:!text-dark focus-visible:outline-secondary flex items-center text-sm transition-all duration-200 ease-in-out focus-visible:rounded-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2",
        {
          "liked text-brand font-semibold": isLikedByCurrentUser,
          "text-default font-medium": !isLikedByCurrentUser,
          "cursor-not-allowed": !post_details_loaded,
        },
        shouldAnimate ? "scale-110" : "scale-100",
        isV3Enabled ? "px-1 py-0.5" : "px-2 py-1.5",
      )}
    >
      {isV3Enabled ? (
        <Icon
          type={isLikedByCurrentUser ? "24-heart-red-fill" : "24-heart-outline"}
        />
      ) : (
        <>
          <span className="action__icon mr-1.5 inline-flex">
            <Icon type="like" />
          </span>
          <span
            aria-checked={isLikedByCurrentUser}
            className={classnames("action__label")}
          >
            {t(isLikedByCurrentUser ? "liked" : "like")}
          </span>
        </>
      )}
    </button>
  );
};
