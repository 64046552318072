import PropTypes from "prop-types";
import classNames from "classnames";
import { t } from "i18n-js";
import { Button } from "@circle-react-uikit/Button";
import { CheckIcon } from "./RsvpIcons";

export const RsvpButton = ({
  confirmed,
  full,
  toggleRsvp,
  loading,
  paidEvent,
  variant = "primary",
  isRsvpDisabled = false,
}) => (
  <>
    {!confirmed && (
      <Button
        variant={variant}
        full={full}
        onClick={toggleRsvp}
        disabled={loading || isRsvpDisabled}
        className={classNames({ "!border-primary": variant === "secondary" })}
      >
        {paidEvent ? t("events.rsvp.buy_ticket") : t("events.rsvp.rsvp")}
      </Button>
    )}
    {confirmed && <CheckIcon />}
  </>
);

RsvpButton.propTypes = {
  confirmed: PropTypes.bool,
  full: PropTypes.bool,
  toggleRsvp: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  variant: PropTypes.string,
};
