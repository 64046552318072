import { useState } from "react";
import { useCurrentUser } from "@circle-react/contexts";
import { dateIsPast } from "@circle-react/helpers/dateTimeHelpers";
import { useToast } from "@circle-react-uikit/ToastV2";
import { useIsEventOwner } from "../../hooks/useIsEventOwner";

export const useEventRsvpData = (event: any) => {
  const {
    rsvp_status: rsvpStatus,
    event_setting_attributes: eventSettingAttributes,
    paywall_attributes: ticketData,
  } = event || {};
  const { ends_at: endsAt } = eventSettingAttributes || {};
  const hasEventEnded = dateIsPast(endsAt);
  const [isLoading, setIsLoading] = useState(false);
  const [shouldShowUnrsvpConfirmation, setShouldShowUnrsvpConfirmation] =
    useState(false);

  const sessionStorageCalendarEventKey = `CALENDAR_EVENT_${event.id}`;
  const getConfirmationStorageValue = () => {
    const value = window.sessionStorage.getItem(sessionStorageCalendarEventKey);
    return value === "true" ? true : false; // Session storage stores value as string
  };

  const [shouldShowConfirmation, setShouldShowConfirmation] = useState(
    getConfirmationStorageValue(),
  );

  const setConfirmationValue = (value: any) => {
    try {
      window.sessionStorage.setItem(sessionStorageCalendarEventKey, value);
    } catch (error) {
      console.error("Error accessing session storage", error);
    }
    setShouldShowConfirmation(value);
  };

  const currentUser = useCurrentUser();
  const { error } = useToast();
  const isConfirmed = rsvpStatus === "yes";
  const isUnconfirmed = !rsvpStatus || rsvpStatus === "no";
  const isEventOwner = useIsEventOwner(event);
  const role = isEventOwner ? "host" : "participant";

  return {
    currentUser,
    error,
    hasEventEnded,
    isConfirmed,
    isLoading,
    isUnconfirmed,
    role,
    setShouldShowConfirmation: setConfirmationValue,
    shouldShowConfirmation,
    setIsLoading,
    rsvpStatus,
    ticketData,
    setShouldShowUnrsvpConfirmation,
    shouldShowUnrsvpConfirmation,
  };
};
