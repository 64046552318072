import PropTypes from "prop-types";
import { t } from "i18n-js";
import { openUrl } from "@circle-react/helpers/urlHelpers/openUrl";
import { Button } from "@circle-react-uikit/Button";
import { Modal } from "@circle-react-uikit/Modal";
import { ConfirmationDate } from "./ConfirmationDate";
import "./styles.scss";

export const ConfirmationModal = ({
  event,
  show,
  onClose,
  shouldShowConfirmationDate = true,
  isRecurring = false,
  eventsCount,
}) => {
  const { name, event_setting_attributes: eventData } = event || {};
  const {
    confirmation_message_title: title,
    confirmation_message_description: message,
    confirmation_message_button_title: buttonLabel,
    confirmation_message_button_link: buttonLink,
  } = eventData || {};

  const onButtonClick = () => {
    if (buttonLink) {
      openUrl(buttonLink);
    }
    onClose();
  };

  return (
    <Modal variant="default" show={!!show} onClose={onClose} overflowVisible>
      <div className="event-confirmation">
        <h2>{t("events.notifications.event_setting_attributes.clap")}</h2>
        <h3>
          {title ||
            t(
              "events.notifications.event_setting_attributes.confirmation_message_title_placeholder",
              { event_name: name },
            )}
        </h3>
        <p>
          {isRecurring
            ? message ||
              t("events.rsvp.recurring_modal.confirmation_description", {
                events: `${eventsCount} ${
                  eventsCount === 1
                    ? t("events.rsvp.recurring_modal.event")
                    : t("events.rsvp.recurring_modal.events")
                }`,
              })
            : message ||
              t(
                "events.notifications.event_setting_attributes.confirmation_message_description_placeholder",
              )}
        </p>
        {shouldShowConfirmationDate && (
          <ConfirmationDate
            event={event}
            label={isRecurring ? t("add_all_events_to_calendar") : ""}
          />
        )}
        <Button variant="primary" full onClick={onButtonClick}>
          {buttonLabel ||
            t(
              "events.notifications.event_setting_attributes.confirmation_message_button_title_placeholder",
            )}
        </Button>
      </div>
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  event: PropTypes.shape({
    event_setting_attributes: PropTypes.shape({
      starts_at: PropTypes.string.isRequired,
      ends_at: PropTypes.string.isRequired,
      confirmation_message_title: PropTypes.string,
      confirmation_message_description: PropTypes.string,
      confirmation_message_button_title: PropTypes.string,
      confirmation_message_button_link: PropTypes.string,
    }).isRequired,
  }).isRequired,
  show: PropTypes.any,
  onClose: PropTypes.func.isRequired,
  shouldShowConfirmationDate: PropTypes.bool,
  isRecurring: PropTypes.bool,
  eventsCount: PropTypes.number,
};
