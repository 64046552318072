import { useRef } from "react";
import classNames from "classnames";
import { useClickAway } from "react-use";
import { useChatRoomStore } from "@circle-react/hooks/chatsV2";
import { doesActiveMessageIdMatches } from "../../helpers";
import { DropdownActions } from "./DropdownActions";
import { HoverActions } from "./HoverActions";

export interface MessageActionsProps {
  canUserModifyMessage?: boolean;
  chatRoom: any;
  chatThreadsEnabled?: boolean;
  currentParticipant?: any;
  isMessageThread?: boolean;
  isParentMessage?: boolean;
  isPinned?: boolean;
  isPinnedMessageAtTop?: boolean;
  isRenderedOnHeader?: boolean;
  isLiveStream?: boolean;
  message: any;
  onBookmarkToggle?: (messageId: any) => void;
  setEditMessageId: (id: any) => void;
  setUnreadMessageFlow?: (value: boolean) => void;
  toggleDeleteConfirmationModal: () => void;
  chatRoomUrl?: string;
  messagesContainerId?: string;
}

export const MessageActions = ({
  isPinned = false,
  setEditMessageId,
  currentParticipant = {},
  toggleDeleteConfirmationModal,
  message,
  chatRoom,
  isMessageThread = false,
  canUserModifyMessage = false,
  chatThreadsEnabled = false,
  isParentMessage = false,
  isPinnedMessageAtTop = false,
  isRenderedOnHeader = false,
  onBookmarkToggle,
  setUnreadMessageFlow,
  isLiveStream = false,
  chatRoomUrl,
  messagesContainerId,
}: MessageActionsProps) => {
  const clickHandlerRef = useRef(null);

  const handleOutsideClick = () => {
    setActiveMessageId(null);
  };

  const { activeMessageId, setActiveMessageId } = useChatRoomStore();
  const messageActionsId = `message-actions-${message.id}`;
  useClickAway(clickHandlerRef, handleOutsideClick);

  const shouldActiveMessageIdMatches = doesActiveMessageIdMatches({
    activeMessageId,
    id: message.id,
    isMessageThread,
  });

  return (
    <div className="absolute right-0 top-0 mb-3">
      <div
        id={messageActionsId}
        className={classNames(
          "border-primary bg-primary -mt-2 mr-4 flex rounded-md border",
          {
            "!mt-2": isPinnedMessageAtTop,
            invisible: !activeMessageId || !shouldActiveMessageIdMatches,
            "group-hover/action:visible":
              !activeMessageId || shouldActiveMessageIdMatches,
          },
        )}
      >
        <div className="flex min-h-[34px] items-center justify-around space-x-1 px-1">
          <HoverActions
            message={message}
            isMessageThread={isMessageThread}
            chatThreadsEnabled={chatThreadsEnabled}
            chatRoom={chatRoom}
            isParentMessage={isParentMessage}
            isRenderedOnHeader={isRenderedOnHeader}
            currentParticipant={currentParticipant}
            onBookmarkToggle={onBookmarkToggle}
          />
          {currentParticipant && (
            <div ref={clickHandlerRef}>
              <DropdownActions
                isPinned={isPinned}
                setEditMessageId={setEditMessageId}
                currentParticipant={currentParticipant}
                toggleDeleteConfirmationModal={toggleDeleteConfirmationModal}
                message={message}
                chatRoom={chatRoom}
                isMessageThread={isMessageThread}
                canUserModifyMessage={canUserModifyMessage}
                setUnreadMessageFlow={setUnreadMessageFlow}
                isLiveStream={isLiveStream}
                chatRoomUrl={chatRoomUrl}
                setActiveMessageId={setActiveMessageId}
                messagesContainerId={messagesContainerId}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
