import { t } from "i18n-js";
import { ShowPageLink } from "@/react/components/PostsV3/PostViewListItem/ShowPageLink";
import { useCurrentPostHelpers } from "@circle-react/components/PostsV3/useCurrentPostHelpers";
import { Icon } from "@circle-react-shared/Icon";
import { CommentCountLink } from "./CommentCountLink";

export const CommentsLink = ({ shouldOpenCommentPage, toggleComment }: any) => {
  const { post } = useCurrentPostHelpers();

  const {
    is_comments_enabled: isCommentsEnabled,
    comments_count: commentsCount = 0,
    post_details_loaded,
  } = post;

  const isCommentsLink = isCommentsEnabled && shouldOpenCommentPage;

  if (!isCommentsLink) return null;

  return (
    <ShowPageLink
      post={post}
      allComments
      className="flex w-full !items-center !justify-between"
      shouldOpenPostModal
    >
      <div className="flex items-center">
        <span className="action__icon">
          <Icon type="message" className="!text-dark" useWithFillCurrentColor />
        </span>
        <span className="count !text-dark">{t("cap_comment")}</span>
      </div>
      <div className="flex items-center">
        <CommentCountLink
          count={commentsCount}
          toggleComment={toggleComment}
          hasPostDetailsLoaded={post_details_loaded}
        />
      </div>
    </ShowPageLink>
  );
};
