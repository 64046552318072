import { isArray, join, map, split } from "lodash";
import { MEDIA_NODE_TYPES } from "./useMinimalTipTapContent";

export const isMediaContentInEditorEmpty = (
  mediaContent: Record<string, unknown> | null,
): boolean => {
  let isEmpty = true;

  if (mediaContent && isArray(mediaContent.content)) {
    isEmpty = !mediaContent.content.some((item: any) =>
      MEDIA_NODE_TYPES.includes(item.type),
    );
  }

  return isEmpty;
};

export const isOtherContentInEditorEmpty = (
  otherContent: Record<string, unknown> | null,
): boolean => {
  let isEmpty = true;

  if (otherContent && isArray(otherContent.content)) {
    isEmpty = !otherContent.content.some(
      (item: any) => !MEDIA_NODE_TYPES.includes(item.type),
    );
  }

  return isEmpty;
};

export const convertNewlinesToBr = (text: string): string => {
  const lines = split(text, "\n");
  return join(
    map(lines, (line, index) =>
      index === lines.length - 1 ? line : `${line}<br />`,
    ),
    "",
  );
};
