import { useMemo } from "react";
import { flatten, uniqBy } from "lodash";
import { useInfiniteQuery, useQueryClient } from "react-query";
import { reactQueryGet } from "@circle-react/helpers/backendRequestHelpers";
import { internalApi } from "@circle-react/helpers/urlHelpers";
import { useInfiniteQueryHelpers } from "../reactQuery/useInfiniteQueryHelpers";

export const useProfilePostsList = ({
  memberPublicId,
  params = { per_page: 10 },
}) => {
  const queryKey = ["profile-posts", memberPublicId];
  const fetchPage = page =>
    reactQueryGet(
      internalApi.profile.posts({
        memberPublicId,
        params: { page, ...params },
      }),
    );
  const { findAndReplaceRecord } = useInfiniteQueryHelpers();

  const queryClient = useQueryClient();

  const onPostChange = async post => {
    const fetchedPost = await queryClient.fetchQuery(
      internalApi.posts.show({
        spaceId: post.space_id,
        postSlug: post.slug,
      }),
    );
    return findAndReplaceRecord(fetchedPost, queryKey);
  };

  const infinitePostsQuery = useInfiniteQuery(
    queryKey,
    ({ pageParam = 1 }) => fetchPage(pageParam),
    {
      getNextPageParam: lastPage =>
        lastPage.has_next_page ? lastPage.page + 1 : undefined,
    },
  );

  const posts = useMemo(
    () =>
      infinitePostsQuery.isLoading
        ? []
        : uniqBy(
            flatten(infinitePostsQuery.data.pages.map(page => page.records)),
            "id",
          ),
    [infinitePostsQuery.data],
  );

  return {
    ...infinitePostsQuery,
    onPostChange,
    posts,
  };
};
