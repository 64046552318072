import { truncate } from "lodash";
import { EmbeddableChat } from "@circle-react/components/Chat/EmbeddableChat";
import { ChatThreads } from "@circle-react/components/Chat/MessagingV2/ChatThreads";
import { Header } from "@circle-react/components/Chat/MessagingV2/ChatThreads/Header";
import { RailbarContextProvider } from "@circle-react/hooks/chatsV2";
import { ChatRoomContextProvider } from "@circle-react/hooks/chatsV2/useChatRoomContext";
import { Rail } from "@circle-react-shared/uikit/Rail";

interface ProfileMessaging {
  uuid: string;
  title: string;
  onClose: () => void;
}

export const ProfileMessaging = ({
  uuid,
  title,
  onClose,
}: ProfileMessaging) => (
  <div className="standard-layout-v2 profile-messaging">
    <ChatRoomContextProvider chatRoomUuid={uuid} isMessaging>
      <RailbarContextProvider isDrawer>
        <Rail.Header className="h-[64px]">
          <Header title={truncate(title, { length: 25 })} onClose={onClose} />
        </Rail.Header>
        <EmbeddableChat
          uuid={uuid}
          displayDayChange
          displayRoomName={false}
          isDrawer
        />
        <div id="rail-bar-drawer" />
        <ChatThreads />
      </RailbarContextProvider>
    </ChatRoomContextProvider>
  </div>
);
