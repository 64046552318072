import classNames from "classnames";
import { t } from "i18n-js";

interface Member {
  is_deleted: boolean;
  admin: boolean;
  moderator: boolean;
}

export interface BadgeProps {
  member: Member;
}

const getBadgeText = (member: Member) => {
  if (member.is_deleted) {
    return t("deactivated");
  }

  if (member.admin) {
    return t("admin");
  }

  if (member.moderator) {
    return t("moderator");
  }

  return null;
};

export const Badge = ({ member }: BadgeProps) => {
  const text = getBadgeText(member);

  if (!text) {
    return null;
  }

  return (
    <div
      className={classNames(
        "text-badge absolute bottom-[-6px] z-[2] m-0 inline-block select-none overflow-hidden rounded-2xl bg-clip-padding px-2 py-[2px] text-[9px] font-bold uppercase",
        {
          "bg-[#ef4444] text-white": member.is_deleted,
          "bg-brand text-brand-button": !member.is_deleted,
        },
      )}
    >
      {text}
    </div>
  );
};
