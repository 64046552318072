import { useEffect, useRef } from "react";
import {
  addSubscription,
  removeSubscription,
} from "../../channels/subscriptionManager";

export const useWebSocket = (
  {
    channel,
    onMessageReceive,
    canCreateConnection = true, // If we don't want to create a connection while certain conditions are not met, ex: community_member is not present?
    ...passThroughParams
  },
  deps = [],
) => {
  const onMessageReceiveRef = useRef(onMessageReceive);

  useEffect(() => {
    onMessageReceiveRef.current = onMessageReceive;
  }, [channel, onMessageReceive]);

  useEffect(() => {
    if (!canCreateConnection) {
      return;
    }
    addSubscription(
      { channel, ...passThroughParams },
      onMessageReceiveRef.current,
    );

    return () =>
      removeSubscription(
        { channel, ...passThroughParams },
        onMessageReceiveRef.current,
      );
  }, [channel, canCreateConnection, passThroughParams, deps]);
};
